import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'

// 重复点击路由报错
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router)

/**
 * hidden: true                   当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']     设置该路由进入的权限，支持多个权限叠加
    title: 'title'                设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'              设置该路由的图标
    noCache: true                 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    affix: true                   如果设置为true，则标记将附加在标记视图中
    breadcrumb: false             如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/example/list'   如果设置路径，侧栏将突出显示您设置的路径
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [

  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path*',
      component: () =>
        import('@/views/redirect/index')
    }]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile',
    hidden: true,
    children: [{
      path: '/',
      component: () =>
        import('@/views/profile'),
      name: 'Profile',
      meta: {
        title: 'Profile'
      }

    }]
  },

  { path: '/iframe/rma-manage/shopify-return-manage', //
    component: () => import('@/views/rma-manage/shopify-return-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/shopify-return-manage/added', //
    component: () => import('@/views/rma-manage/shopify-return-manage/added'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/shopify-return-manage/shopify-refunds', //
    component: () => import('@/views/rma-manage/shopify-return-manage/shopify-refunds'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/shopify-return-manage/detail', //
    component: () => import('@/views/rma-manage/shopify-return-manage/detail'),
    hidden: true
  },
  // 站内信三期
  {
    path: '/iframe/service-manage/Sensitive-words', //
    component: () => import('@/views/service-manage/Sensitive-words'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Buyer-mail-message/buyer-email', //
    component: () => import('@/views/service-manage/Buyer-mail-message/buyer-email'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Buyer-mail-message/message-reply', //
    component: () => import('@/views/service-manage/Buyer-mail-message/message-reply'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Buyer-mail-message/Block-emails', //
    component: () => import('@/views/service-manage/Buyer-mail-message/Block-emails'),
    hidden: true
  },
  // 中差评管理路由嵌套
  {
    path: '/iframe/bad-review-manage/comment-manage', //
    component: () => import('@/views/bad-review-manage/comment-manage'),
    hidden: true
  },
  {
    path: '/iframe/bad-review-manage/personal-statistics', //
    component: () => import('@/views/bad-review-manage/personal-statistics'),
    hidden: true
  },
  // 订单中心 -订单管理
  {
    path: '/iframe/order-manage/order-center-manage', // 主页
    component: () => import('@/views/order-manage/order-center-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/order-manage/order-center-manage/create-order', // 修改
    component: () => import('@/views/order-manage/order-center-manage/create-order'),
    hidden: true
  },
  {
    path: '/iframe/order-manage/Ebay-allocate-inventory', // 分配库存
    component: () => import('@/views/order-manage/Ebay-allocate-inventory/index'),
    hidden: true
  },
  {
    path: '/iframe/order-manage/order-center-manage/details', // 详情
    component: () => import('@/views/order-manage/order-center-manage/details'),
    hidden: true
  },
  // 订单中心 -创建手工单
  {
    path: '/iframe/order-manage/simple-manual', // 创建
    component: () => import('@/views/order-manage/simple-manual'),
    hidden: true
  },
  // 订单中心 -自发货订单
  {
    path: '/iframe/order-manage/Self-delivery-order',
    component: () => import('@/views/order-manage/Self-delivery-order/index'),
    hidden: true
  },
  {
    path: '/iframe/order-manage/Self-delivery-order/added', // 新增
    component: () => import('@/views/order-manage/Self-delivery-order/added'),
    hidden: true
  },
  {
    path: '/iframe/order-manage/Self-delivery-order/details', // 详情
    component: () => import('@/views/order-manage/Self-delivery-order/details'),
    hidden: true
  },
  // 站内信 - ebay列表
  {
    path: '/iframe/service-manage/Ebay-service-manage/Buyer-message',
    component: () => import('@/views/service-manage/Ebay-service-manage/Buyer-message'),
    hidden: true
  },
  // 站内信 - 消息回复
  {
    path: '/iframe/service-manage/Ebay-service-manage/message-reply',
    component: () => import('@/views/service-manage/Ebay-service-manage/message-reply'),
    hidden: true
  },
  // 站内信 -平台
  {
    path: '/iframe/service-manage/Ebay-service-manage/Platform-message',
    component: () => import('@/views/service-manage/Ebay-service-manage/Platform-message'),
    hidden: true
  },
  // 站内信 - Amazon列表
  {
    path: '/iframe/service-manage/Amazon-service-manage/Buyer-email',
    component: () => import('@/views/service-manage/Amazon-service-manage/Buyer-email'),
    hidden: true
  },
  // 站内信 -消息回复
  {
    path: '/iframe/service-manage/Amazon-service-manage/amazon-message-reply',
    component: () => import('@/views/service-manage/Amazon-service-manage/amazon-message-reply'),
    hidden: true
  },
  // 站内信 -平台
  {
    path: '/iframe/service-manage/Amazon-service-manage/Platform-email',
    component: () => import('@/views/service-manage/Amazon-service-manage/Platform-email'),
    hidden: true
  },
  // 站内信 - walmart列表
  {
    path: '/iframe/service-manage/Walmart-service-manage/Walmart-buyer-email',
    component: () => import('@/views/service-manage/Walmart-service-manage/Walmart-buyer-email'),
    hidden: true
  },
  // 站内信 -消息回复
  {
    path: '/iframe/service-manage/Walmart-service-manage/walmart-message-reply',
    component: () => import('@/views/service-manage/Walmart-service-manage/walmart-message-reply'),
    hidden: true
  },
  // 站内信 -平台
  {
    path: '/iframe/service-manage/Walmart-service-manage/Walmart-platform-email',
    component: () => import('@/views/service-manage/Walmart-service-manage/Walmart-platform-email'),
    hidden: true
  },
  // 站内信 -分配BU
  {
    path: '/iframe/service-manage/Assign-bu',
    component: () => import('@/views/service-manage/Assign-bu'),
    hidden: true
  },
  // 站内信 -个人数据统计
  {
    path: '/iframe/service-manage/Statistics',
    component: () => import('@/views/service-manage/Statistics'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Statistics/statistics-details',
    component: () => import('@/views/service-manage/Statistics/statistics-details'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Ebay-bad-review',
    component: () => import('@/views/service-manage/Ebay-bad-review'),
    hidden: true
  },
  // 站内信 -邮件模板分类管理
  {
    path: '/iframe/service-manage/Email-manage/Email-manage',
    component: () => import('@/views/service-manage/Email-manage'),
    hidden: true
  },
  {
    path: '/iframe/service-manage/Email-manage/sort-mange',
    component: () => import('@/views/service-manage/Email-manage/sort-mange'),
    hidden: true
  },
  // RMA退货退款 - walmart
  {
    path: '/iframe/rma-manage/walmart-return-manage',
    component: () => import('@/views/rma-manage/walmart-return-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/walmart-return-manage/walmart-refunds',
    component: () => import('@/views/rma-manage/walmart-return-manage/walmart-refunds'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/walmart-return-manage/added',
    component: () => import('@/views/rma-manage/walmart-return-manage/added'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/walmart-return-manage/details',
    component: () => import('@/views/rma-manage/walmart-return-manage/details'),
    hidden: true
  },
  // RMA退货退款 - AMAZON
  {
    path: '/iframe/rma-manage/return-manage',
    component: () => import('@/views/rma-manage/return-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/return-manage/refunds',
    component: () => import('@/views/rma-manage/return-manage/refunds'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/return-manage/added',
    component: () => import('@/views/rma-manage/return-manage/added'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/return-manage/detail',
    component: () => import('@/views/rma-manage/return-manage/detail'),
    hidden: true
  },
  // RMA退货退款 - EBAY
  {
    path: '/iframe/rma-manage/ebay-return-manage',
    component: () => import('@/views/rma-manage/ebay-return-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/ebay-return-manage/ebay-refunds',
    component: () => import('@/views/rma-manage/ebay-return-manage/ebay-refunds'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/ebay-return-manage/added',
    component: () => import('@/views/rma-manage/ebay-return-manage/added'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/ebay-return-manage/details',
    component: () => import('@/views/rma-manage/ebay-return-manage/details'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/ebay-return-manage/review',
    component: () => import('@/views/rma-manage/ebay-return-manage/review'),
    hidden: true
  },
  { // 站内信 - shopify列表
    path: '/iframe/service-manage/Shopify-service-manage/Shopify-buyer-email',
    component: () => import('@/views/service-manage/Shopify-service-manage/Shopify-buyer-email'),
    hidden: true
  },
  { // 站内信 -消息回复
    path: '/iframe/service-manage/Shopify-service-manage/shopify-message-reply',
    component: () => import('@/views/service-manage/Shopify-service-manage/shopify-message-reply'),
    hidden: true
  },
  { // 站内信 -屏蔽邮箱
    path: '/iframe/service-manage/Shopify-service-manage/Block-email',
    component: () => import('@/views/service-manage/Shopify-service-manage/Block-email'),
    hidden: true
  },

  { // 标签管理
    path: '/iframe/service-manage/Tag-manage',
    component: () => import('@/views/service-manage/Tag-manage'),
    hidden: true
  },
  { // 标签评论管理
    path: '/iframe/service-manage/Comment-manage',
    component: () => import('@/views/service-manage/Comment-manage'),
    hidden: true
  },
  { // 问卷工单管理
    path: '/iframe/service-manage/questionnaire-manage',
    component: () => import('@/views/service-manage/questionnaire-work-order-manage/QuestionnaireWorkOrderManagement.vue'),
    hidden: true
  },
  { // 评论管理统计
    path: '/iframe/service-manage/Comment-statistics',
    component: () => import('@/views/service-manage/Comment-statistics'),
    hidden: true
  },
  // Stand website退货退款
  {
    path: '/iframe/rma-manage/standwebsite-return-manage',
    component: () => import('@/views/rma-manage/standwebsite-return-manage/index'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/standwebsite-return-manage/standwebsite-refunds',
    component: () => import('@/views/rma-manage/standwebsite-return-manage/standwebsite-refunds'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/standwebsite-return-manage/added',
    component: () => import('@/views/rma-manage/standwebsite-return-manage/added'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/standwebsite-return-manage/detail',
    component: () => import('@/views/rma-manage/standwebsite-return-manage/detail'),
    hidden: true
  },
  {
    path: '/iframe/rma-manage/return-reason-manage',
    component: () => import('@/views/rma-manage/return-reason-manage/index'),
    hidden: true
  },
  // 工单质检管理
  {
    path: '/iframe/service-manage/work-order-quality-inspection',
    component: () => import('@/views/service-manage/work-order-quality-inspection/work-order-quality-inspection/list.vue'),
    hidden: true
  },
  // 评分规则配置
  {
    path: '/iframe/service-manage/work-order-quality-inspection/score-rules-config',
    component: () => import('@/views/service-manage/work-order-quality-inspection/score-rules/list.vue'),
    hidden: true
  },
  // 申诉管理
  {
    path: '/iframe/service-manage/work-order-quality-inspection/escalation',
    component: () => import('@/views/service-manage/work-order-quality-inspection/escalation-manage/list.vue'),
    hidden: true
  },
  // 质检日志
  {
    path: '/iframe/service-manage/work-order-quality-inspection/log',
    component: () => import('@/views/service-manage/work-order-quality-inspection/log/list.vue'),
    hidden: true
  },
  // 客服人员配置
  {
    path: '/iframe/service-manage/customer-service',
    component: () => import('@/views/service-manage/work-order-quality-inspection/customer-service/list.vue'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () =>
      import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () =>
      import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {},
    children: [{
      path: 'dashboard',
      component: () =>
        import('@/views/dashboard/index'),
      name: 'Dashboard',
      meta: {
        title: 'dashboard',
        icon: 'dashboard'
      }
    }]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/order-manage',
    component: Layout,
    // component: () => import('@/views/stock-in/index'),
    name: 'OrderManage',
    redirect: 'noRedirect',
    meta: {
      title: 'OrderManage',
      icon: 'nested'
    },
    children: [
      {
        path: 'Self-delivery-order',
        component: NullRouterView,
        // component: () => import('@/views/stock-in/harvest'),
        meta: {
          title: 'SelfDeliveryOrder'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/Self-delivery-order/index'),
          name: 'SelfDeliveryOrder',
          hidden: true
        },
        {
          path: 'added',
          component: () =>
            import('@/views/order-manage/Self-delivery-order/added'),
          name: 'OrderAdded',
          hidden: true,
          meta: {
            title: 'Added'
          }
        },
        {
          path: 'details',
          component: () => import('@/views/order-manage/Self-delivery-order/details'),
          name: 'OrderDetail',
          hidden: true,
          meta: {
            title: 'Details'
          }
        }]
      },
      {
        path: 'order-center-manage',
        component: NullRouterView,
        // component: () => import('@/views/stock-in/harvest'),
        meta: {
          title: 'OrderCenterManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/order-center-manage/index'),
          name: 'OrderCenterManage',
          hidden: true
        },
        {
          path: 'create-order',
          component: () => import('@/views/order-manage/order-center-manage/create-order'),
          name: 'CreateOrder',
          hidden: true,
          meta: {
            title: 'CreateOrder'
          }
        },
        {
          path: 'details',
          component: () => import('@/views/order-manage/order-center-manage/details'),
          name: 'OrderManageDetail',
          hidden: true,
          meta: {
            title: 'OrderManageDetail'
          }
        }]
      },
      {
        path: 'simple-manual',
        component: NullRouterView,
        meta: {
          title: 'SimpleManual'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/simple-manual'),
          name: 'SimpleManual',
          hidden: true
        }
        ]
      },
      {
        path: 'Platform-planning-inventory',
        component: NullRouterView,
        meta: {
          title: 'PlatformPlanningInventory'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/Platform-planning-inventory/index'),
          name: 'PlatformPlanningInventory',
          hidden: true
        }
        ]
      },
      {
        path: 'Ebay-allocate-inventory',
        component: NullRouterView,
        meta: {
          title: 'EbayAllocateInventory'
        },
        children: [{
          path: '/',
          component: () => import('@/views/order-manage/Ebay-allocate-inventory/index'),
          name: 'EbayAllocateInventory',
          hidden: true
        }
        ]
      }
    ]
  },
  {
    path: '/order-manage',
    component: Layout,
    // component: () => import('@/views/stock-in/index'),
    name: 'OrderManage',
    redirect: 'noRedirect',
    meta: {
      title: 'OrderManage',
      icon: 'nested'
    },
    children: [
      {
        path: 'Self-delivery-order',
        component: NullRouterView,
        // component: () => import('@/views/stock-in/harvest'),
        meta: {
          title: 'SelfDeliveryOrder'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/Self-delivery-order/index'),
          name: 'SelfDeliveryOrder',
          hidden: true
        },
        {
          path: 'added',
          component: () =>
            import('@/views/order-manage/Self-delivery-order/added'),
          name: 'OrderAdded',
          hidden: true,
          meta: {
            title: 'Added'
          }
        },
        {
          path: 'details',
          component: () => import('@/views/order-manage/Self-delivery-order/details'),
          name: 'OrderDetail',
          hidden: true,
          meta: {
            title: 'Details'
          }
        }]
      },
      {
        path: 'order-center-manage',
        component: NullRouterView,
        // component: () => import('@/views/stock-in/harvest'),
        meta: {
          title: 'OrderCenterManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/order-center-manage/index'),
          name: 'OrderCenterManage',
          hidden: true
        },
        {
          path: 'create-order',
          component: () => import('@/views/order-manage/order-center-manage/create-order'),
          name: 'CreateOrder',
          hidden: true,
          meta: {
            title: 'CreateOrder'
          }
        },
        {
          path: 'details',
          component: () => import('@/views/order-manage/order-center-manage/details'),
          name: 'OrderManageDetail',
          hidden: true,
          meta: {
            title: 'OrderManageDetail'
          }
        }]
      },
      {
        path: 'simple-manual',
        component: NullRouterView,
        meta: {
          title: 'SimpleManual'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/simple-manual'),
          name: 'SimpleManual',
          hidden: true
        }
        ]
      },
      {
        path: 'Platform-planning-inventory',
        component: NullRouterView,
        meta: {
          title: 'PlatformPlanningInventory'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/order-manage/Platform-planning-inventory/index'),
          name: 'PlatformPlanningInventory',
          hidden: true
        }
        ]
      },
      {
        path: 'Ebay-allocate-inventory',
        component: NullRouterView,
        meta: {
          title: 'EbayAllocateInventory'
        },
        children: [{
          path: '/',
          component: () => import('@/views/order-manage/Ebay-allocate-inventory/index'),
          name: 'EbayAllocateInventory',
          hidden: true
        }
        ]
      }
    ]
  },
  {
    path: '/bad-review-manage',
    component: Layout,
    // component: () => import('@/views/stock-in/index'),
    name: 'BadReviewManage',
    redirect: 'noRedirect',
    meta: {
      title: 'BadReviewManage',
      icon: 'nested'
    },
    children: [

      {
        path: 'comment-manage',
        component: NullRouterView,
        meta: {
          title: 'CommentManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/bad-review-manage/comment-manage'),
          name: 'CommentManage',
          hidden: true
        }
        ]
      },
      { // 个人统计
        path: 'personal-statistics',
        component: NullRouterView,
        meta: {
          title: 'PersonalStatistics'
        },
        children: [{
          path: '/',
          component: () => import('@/views/bad-review-manage/personal-statistics'),
          name: 'PersonalStatistics',
          hidden: true
        }
        ]
      }
    ]
  },
  // 客服管理
  {
    path: '/service-manage',
    component: Layout,
    name: 'ServiceManage',
    redirect: 'noRedirect',
    meta: {
      title: 'ServiceManage',
      icon: 'nested'
    },
    children: [
      {
        path: 'Ebay-service-manage',
        component: NullRouterView,
        meta: {
          title: 'EbayServiceManage'
        },
        children: [{
          path: 'Buyer-message',
          component: () => import('@/views/service-manage/Ebay-service-manage/Buyer-message/index'),
          name: 'BuyerMessage',
          hidden: true
        },
        {
          path: 'Platform-message',
          component: () => import('@/views/service-manage/Ebay-service-manage/Platform-message/index'),
          name: 'PlatformMessage',
          hidden: true
        }]
      },
      {
        path: 'Amazon-service-manage',
        component: NullRouterView,
        meta: {
          title: 'AmazonServiceManage'
        },
        children: [{
          path: 'Buyer-email',
          component: () => import('@/views/service-manage/Amazon-service-manage/Buyer-email/index'),
          name: 'BuyerEmail',
          hidden: true
        },

        {
          path: 'Platform-email',
          component: () => import('@/views/service-manage/Amazon-service-manage/Platform-email/index'),
          name: 'PlatformEmail',
          hidden: true
        }
        ]
      },
      {
        path: 'Shopify-service-manage',
        component: NullRouterView,
        meta: {
          title: 'ShopifyServiceManage'
        },
        children: [{
          path: '/',
          component: () => () => import('@/views/service-manage/Shopify-service-manage/Shopify-buyer-email'),
          name: 'ShopifyBuyerEmail',
          hidden: true
        },
        {
          path: 'Block-email',
          component: () => import('@/views/service-manage/Shopify-service-manage/Block-email'),
          name: 'ShopifyBlockMail',
          hidden: true,
          meta: {
            title: 'BlockEmail'
          }
        }
        ]
      },
      {
        path: 'Email-manage',
        component: NullRouterView,
        meta: {
          title: 'EmailManage'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Email-manage/index'),
          name: 'EmailTemplate',
          hidden: true
        }
        ]
      },
      {
        path: 'Ebay-bad-review',
        component: NullRouterView,
        meta: {
          title: 'EbayBadReview'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Ebay-bad-review'),
          name: 'EbayBadReview',
          hidden: true
        }
        ]
      },
      {
        path: 'Assign-bu',
        component: NullRouterView,
        meta: {
          title: 'AssignBU'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Assign-bu'),
          name: 'AssignBU',
          hidden: true
        }
        ]
      },
      { // 标签管理
        path: 'Tag-manage',
        component: NullRouterView,
        meta: {
          title: 'TagManage'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Tag-manage'),
          name: 'TagManage',
          hidden: true
        }
        ]
      },
      { // 评论管理
        path: 'Comment-manage',
        component: NullRouterView,
        meta: {
          title: 'CommentTagManage'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Comment-manage'),
          name: 'CommentTagManage',
          hidden: true
        }
        ]
      },
      { // 评论管理统计
        path: 'Comment-statistics',
        component: NullRouterView,
        meta: {
          title: 'CommentStatistics'
        },
        children: [{
          path: '/',
          component: () => import('@/views/service-manage/Comment-statistics'),
          name: 'CommentStatistics',
          hidden: true
        }
        ]
      }
    ]
  },
  {
    path: '/rma-manage',
    component: Layout,
    // component: () => import('@/views/stock-in/index'),
    name: 'RMAManage',
    redirect: 'noRedirect',
    meta: {
      title: 'RMAManage',
      icon: 'nested'
    },
    children: [
      {
        path: 'return-manage',
        component: NullRouterView,
        meta: {
          title: 'ReturnManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/rma-manage/return-manage/index'),
          name: 'ReturnManage',
          hidden: true
        },
        {
          path: 'detail',
          component: () => import('@/views/rma-manage/return-manage/detail'),
          name: 'RMADetail',
          hidden: true,
          meta: {
            title: 'RMADetail'
          }
        },
        {
          path: 'refunds',
          component: () => import('@/views/rma-manage/return-manage/refunds'),
          name: 'Refunds',
          hidden: true,
          meta: {
            title: 'Refunds'
          }
        },
        {
          path: 'added',
          component: () => import('@/views/rma-manage/return-manage/added'),
          name: 'AmazonAdded',
          hidden: true,
          meta: {
            title: 'AmazonAdded'
          }
        }
        ]
      },
      {
        path: 'ebay-return-manage',
        component: NullRouterView,
        meta: {
          title: 'EbayReturnManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/rma-manage/ebay-return-manage/index'),
          name: 'EbayReturnManage',
          hidden: true
        },
        {
          path: 'details',
          component: () => import('@/views/rma-manage/ebay-return-manage/details'),
          name: 'EbayDetails',
          hidden: true,
          meta: {
            title: 'EbayDetails'
          }
        },
        {
          path: 'added',
          component: () => import('@/views/rma-manage/ebay-return-manage/added'),
          name: 'AddedRMA',
          hidden: true,
          meta: {
            title: 'AddedRMA'
          }
        },
        {
          path: 'ebay-refunds',
          component: () => import('@/views/rma-manage/ebay-return-manage/ebay-refunds'),
          name: 'EbayRefunds',
          hidden: true,
          meta: {
            title: 'EbayRefunds'
          }
        },
        {
          path: 'review',
          component: () => import('@/views/rma-manage/ebay-return-manage/review'),
          name: 'Review',
          hidden: true,
          meta: {
            title: 'Review'
          }
        }
        ]
      },
      {
        path: 'walmart-return-manage',
        component: NullRouterView,
        meta: {
          title: 'WalmartReturnManage'
        },
        children: [{
          path: '/',
          component: () =>
            import('@/views/rma-manage/walmart-return-manage/index'),
          name: 'WalmartReturnManage',
          hidden: true
        },
        {
          path: 'details',
          component: () => import('@/views/rma-manage/walmart-return-manage/details'),
          name: 'WalmartDetails',
          hidden: true,
          meta: {
            title: 'WalmartDetails'
          }
        },
        {
          path: 'added',
          component: () => import('@/views/rma-manage/walmart-return-manage/added'),
          name: 'WalmartAddedRMA',
          hidden: true,
          meta: {
            title: 'WalmartAddedRMA'
          }
        },
        {
          path: 'walmart-refunds',
          component: () => import('@/views/rma-manage/walmart-return-manage/walmart-refunds'),
          name: 'WalmartRefunds',
          hidden: true,
          meta: {
            title: 'WalmartRefunds'
          }
        }
        ]
      }

    ]
  },
  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }

]
const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
