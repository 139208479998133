import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'

const routerMap = {
  Layout,
  NullRouterView,
  SelfDeliveryOrder: () => import('@/views/order-manage/Self-delivery-order/index'),
  OrderAdded: () => import('@/views/order-manage/Self-delivery-order/added'),
  OrderDetail: () => import('@/views/order-manage/Self-delivery-order/details'),
  PlatformPlanningInventory: () => import('@/views/order-manage/Platform-planning-inventory/index'),
  EbayAllocateInventory: () => import('@/views/order-manage/Ebay-allocate-inventory/index'),
  ReturnManage: () => import('@/views/rma-manage/return-manage/index'),
  RMADetail: () => import('@/views/rma-manage/return-manage/detail'),
  BuyerMessage: () => import('@/views/service-manage/Ebay-service-manage/Buyer-message'),
  PlatformMessage: () => import('@/views/service-manage/Ebay-service-manage/Platform-message'),
  EmailTemplate: () => import('@/views/service-manage/Email-manage'),
  BuyerEmail: () => import('@/views/service-manage/Amazon-service-manage/Buyer-email'),
  PlatformEmail: () => import('@/views/service-manage/Amazon-service-manage/Platform-email'),
  Refunds: () => import('@/views/rma-manage/return-manage/refunds'),
  EbayReturnManage: () => import('@/views/rma-manage/ebay-return-manage/index'),
  EbayDetails: () => import('@/views/rma-manage/ebay-return-manage/details'),
  AddedRMA: () => import('@/views/rma-manage/ebay-return-manage/added'),
  EbayRefunds: () => import('@/views/rma-manage/ebay-return-manage/ebay-refunds'),
  Review: () => import('@/views/rma-manage/ebay-return-manage/review'),
  AmazonAdded: () => import('@/views/rma-manage/return-manage/added/crate-rma.vue'),
  WalmartDetails: () => import('@/views/rma-manage/walmart-return-manage/details'),
  WalmartAddedRMA: () => import('@/views/rma-manage/walmart-return-manage/added'),
  WalmartRefunds: () => import('@/views/rma-manage/walmart-return-manage/walmart-refunds'),
  WalmartReturnManage: () => import('@/views/rma-manage/walmart-return-manage/index'),
  // ebay差评管理
  EbayBadReview: () => import('@/views/service-manage/Ebay-bad-review'),
  // 分配BU
  AssignBU: () => import('@/views/service-manage/Assign-bu'),
  // 个人数据统计
  Statistics: () => import('@/views/service-manage/Statistics'),
  StatisticsDetails: () => import('@/views/service-manage/Statistics/statistics-details'),
  // 邮件模板分类管理
  sortMange: () => import('@/views/service-manage/Email-manage/sort-mange'),
  // 沃尔玛邮件
  WalmartBuyerEmail: () => import('@/views/service-manage/Walmart-service-manage/Walmart-buyer-email'),
  WalmartPlatformEmail: () => import('@/views/service-manage/Walmart-service-manage/Walmart-platform-email'),
  SimpleManual: () => import('@/views/order-manage/simple-manual'),
  OrderCenterManage: () => import('@/views/order-manage/order-center-manage/index'),
  CreateOrder: () => import('@/views/order-manage/order-center-manage/create-order'),
  OrderManageDetail: () => import('@/views/order-manage/order-center-manage/details'),
  MessageReply: () => import('@/views/service-manage/Ebay-service-manage/message-reply'),
  AmazonMessageReply: () => import('@/views/service-manage/Amazon-service-manage/amazon-message-reply'),
  WalmartMessageReply: () => import('@/views/service-manage/Walmart-service-manage/walmart-message-reply'),
  ShopifyBuyerEmail: () => import('@/views/service-manage/Shopify-service-manage/Shopify-buyer-email'),
  ShopifyBlockMail: () => import('@/views/service-manage/Shopify-service-manage/Block-email'),
  ShopifyMessageReply: () => import('@/views/service-manage/Shopify-service-manage/shopify-message-reply'),
  // 评论管理
  CommentManage: () => import('@/views/bad-review-manage/comment-manage'),
  // 个人统计
  PersonalStatistics: () => import('@/views/bad-review-manage/personal-statistics'),
  // 敏感词管理
  SensitiveWords: () => import('@/views/service-manage/Sensitive-words'),
  // 敏感词管理
  BuyerMailMessage: () => import('@/views/service-manage/Buyer-mail-message/buyer-email'),
  BuyerMessageReply: () => import('@/views/service-manage/Buyer-mail-message/message-reply'),
  BlockEmails: () => import('@/views/service-manage/Buyer-mail-message/Block-emails'),
  // 创建RMA
  ShopifyDetails: () => import('@/views/rma-manage/shopify-return-manage/detail'),
  ShopifyAddedRMA: () => import('@/views/rma-manage/shopify-return-manage/added'),
  ShopifyRefunds: () => import('@/views/rma-manage/shopify-return-manage/shopify-refunds'),
  ShopifyReturnManage: () => import('@/views/rma-manage/shopify-return-manage/index'),
  // 站内信标签管理
  TagManage: () => import('@/views/service-manage/Tag-manage'),
  CommentTagManage: () => import('@/views/service-manage/Comment-manage'),
  CommentStatistics: () => import('@/views/service-manage/Comment-statistics'),
  // 绩效统计
  PerformanceField: () => import('@/views/performance-statistics/field'),
  PerformanceRules: () => import('@/views/performance-statistics/rules'),
  PerformanceRulesAdd: () => import('@/views/performance-statistics/rules/details'),
  PerformanceRulesDetails: () => import('@/views/performance-statistics/rules/details'),
  PerformanceRankings: () => import('@/views/performance-statistics/rankings'),
  PerformanceDetails: () => import('@/views/performance-statistics/details'),
  InputIndicator: () => import('@/views/performance-statistics/input-indicator'),
  // 创建RMA
  StandwebsiteDetails: () => import('@/views/rma-manage/standwebsite-return-manage/detail'),
  StandwebsiteAddedRMA: () => import('@/views/rma-manage/standwebsite-return-manage/added'),
  StandwebsiteRefunds: () => import('@/views/rma-manage/standwebsite-return-manage/standwebsite-refunds'),
  StandwebsiteReturnManage: () => import('@/views/rma-manage/standwebsite-return-manage/index'),
  ReturnReasonManage: () => import('@/views/rma-manage/return-reason-manage'),
  // 自发货物流信息
  SelfDeliveryLogisticsInformation: () => import('@/views/order-manage/self-delivery-logistics-information'),
  // 客户信息
  CustomerInformation: () => import('@/views/order-manage/customer-information'),
  // 问卷工单
  QuestionnaireWorkOrderManagement: () => import('@/views/service-manage/questionnaire-work-order-manage/QuestionnaireWorkOrderManagement.vue'),
  // 工单质检
  WorkOrderQualityInspection: () => import('@/views/service-manage/work-order-quality-inspection/work-order-quality-inspection/list.vue'),
  // 申诉管理
  EscalationManage: () => import('@/views/service-manage/work-order-quality-inspection/escalation-manage/list.vue'),
  // 质检日志
  QualityInspectionLog: () => import('@/views/service-manage/work-order-quality-inspection/log/list.vue'),
  // 评分规则
  ScoreRules: () => import('@/views/service-manage/work-order-quality-inspection/score-rules/list.vue'),
  // 客服人员管理
  CustomerService: () => import('@/views/service-manage/work-order-quality-inspection/customer-service/list.vue'),
  // 导出列表
  ExportList: () => import('@/views/async-export/export-list'),
  // 导出配置
  ExportConfig: () => import('@/views/async-export/export-config'),
  // shein退货退款
  SheInReturnManage: () => import('@/views/rma-manage/shein-return-manage/index'),
  SheInReturnInfo: () => import('@/views/rma-manage/shein-return-manage/return-info.vue'),
  // 库存可视化
  // 可售库存管理
  SellableInventory: () => import('@/views/inventory-visualization/sellable-inventory/list.vue'),
  // 自动推送规则
  AutoPushRules: () => import('@/views/inventory-visualization/auto-push-rules/list.vue'),
  // 虚拟仓配置
  VirtualWarehouseConfig: () => import('@/views/inventory-visualization/virtual-warehouse-config/list.vue'),
  // 履约仓配置
  ComplianceWarehouseConfig: () => import('@/views/inventory-visualization/compliance-warehouse-config/list.vue'),
  // 物流商映射
  LogisticsProviderMapping: () => import('@/views/order-manage/logistics-provider-mapping/list.vue'),
  // TemuSKU
  TemuSKU: () => import('@/views/order-manage/temu-sku'),
  // 发起邮件工单
  SendEmail: () => import('@/views/rma-manage/send-email'),
  // 可售库存管理
  // 渠道库存共享
  ChannelInventoryShared: () => import('@/views/available-inventory/channel-inventory-shared'),
  // 各店铺可售库存
  StoreInventory: () => import('@/views/available-inventory/store-inventory'),
  // 渠道可售库存分配
  ChannelInventoryDistribute: () => import('@/views/available-inventory/channel-inventory-distribute')
}

export default routerMap
