import rich from './rich'

const install = function(Vue) {
  Vue.directive('rich', rich)
}

if (window.Vue) {
  window['rich'] = rich
  Vue.use(install); // eslint-disable-line
}

rich.install = install
export default rich
