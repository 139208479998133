export default {
  bind(el, binding, vnode) {
    const html = binding.value
    if (html) {
      el.innerHTML = html
      const imgReg = /<img.*?(?:>|\/>)/gi
      const srcReg = /src=['"]?([^'"]*)['"]?/i
      const imgList = html.match(imgReg)
      imgList?.forEach((e, i) => {
        const src = e.match(srcReg)
        const img = el.querySelector('img[src="' + src[1] + '"]')
        if (!img.onclick) {
          img.onclick = () => {
            vnode.context.initialIndex = i
            // prevent body scroll
            vnode.context.prevOverflow = document.body.style.overflow
            document.body.style.overflow = 'hidden'
            vnode.context.showViewer = true
          }
        }
      })
      vnode.context.imgList = imgList?.map(e => e.match(srcReg)?.[1]) || []
    }
  }
}
