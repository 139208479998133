<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { checkVersion, unCheckVersion } from 'version-rocket'

export default {
  name: 'App',
  beforeMount() {
    this.checkVersion()
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkVersion()
      } else {
        unCheckVersion({ closeWorker: true })
      }
    })
  },
  methods: {
    checkVersion() {
      if (location.host.startsWith('localhost')) return
      // https://github.com/guMcrey/version-rocket/blob/main/README.zh-CN.md#api
      checkVersion(
        // config
        {
          // 10秒检测一次版本
          pollingTime: 10000,
          localPackageVersion: __APP_VERSION__,
          originVersionFileUrl: `${location.origin}/version.json`
        },
        // options
        {
          title: 'ERP',
          description: '检测到新版本',
          buttonText: '立即更新',
          primaryColor: '#409EFF',
          rocketColor: '#ff8600'
        },
      )
    }
  }
}
</script>
