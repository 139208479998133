import { StatusEN } from './modules/self-order'

export default {
  ...StatusEN,
  route: {
    SendEmail: 'Initiate an email ticket',
    dashboard: 'Homepage',
    BasicData: 'Basic data',
    BasicInformation: 'Basic information',
    StaffInformation: 'Staff information',
    OneCategory: 'One category',
    TowCategory: 'Tow category',
    ThreeCategory: 'Three category',
    SkuMaterialComponent: 'Sku material component',
    MaterialInformation: 'Material information',
    SkuOtherInformation: 'Sku other information',
    SkuPlatform: 'Sku platform',
    StorageArea: 'Storage area',
    WarehousePlatform: 'Warehouse platform',
    JobGroup: 'Job group',
    ThirdParty: 'Third party',
    /** */
    StockIn: 'Stock in',
    InboundOrder: 'Inbound order',
    ShelfTask: 'Shelf task',
    returnsReceipt: 'Returns receipt',
    /** */
    StockOut: 'Stock Out',
    SalesOutbound: 'Sales outbound',
    ShipManagement: 'Ship management',
    PickingTask: 'Picking task',
    stockAllot: 'Stock allot',
    stockOutAbnormal: 'Stock Out abnormal',
    /** */
    InHouseWork: 'In-house work',
    CheckManager: 'Check manager',
    InSurplus: 'In surplus',
    CheckLose: 'Check lose',
    MoveManager: 'Move manager',
    /** */
    stockManage: 'Stock manage',
    stockManageList: 'stock manage list',
    /** */
    AttrSettingManage: 'Attr setting manage',
    ImportFile: 'Import file',
    systemTools: 'System Tool',
    boxInfoImport: 'Box Info Import',
    EbayAllocateInventory: 'Ebay Allocate Inventory',
    BuyerMessage: 'Buyer Message',
    PlatformMessage: 'Platform Message',
    EmailTemplate: 'Email Template',
    ServiceManage: 'Service Manage',
    EbayServiceManage: 'Ebay Service Manage',
    BuyerEmail: 'Buyer Email',
    AmazonServiceManage: 'Amazon Service Manage',
    PlatformEmail: 'Platform Email',
    BuyeremailInfo: 'Buyeremail Info',
    PlatformemailInfo: 'Platformemail Info',
    EmailManage: 'Email Manage',
    BadReviewManage: 'Bad Review Manage',
    CommentManage: 'Comment Manage',
    StyleStatistics: 'Style Statistics',
    SkuStatistics: 'Sku Statistics',
    PersonalStatistics: 'Personal Statistics',
    SimpleManual: 'Replacement',
    SystemManual: 'System Manual',
    PerformanceStatistics: 'Performance statistics',
    PerformanceField: 'Performance field',
    PerformanceRules: 'Performance rules',
    PerformanceRankings: 'Performance rankings',
    PerformanceDetails: 'Performance details',
    InputIndicator: 'Input indicator',
    ExportManage: 'Export Manage',
    ExportInfo: 'Download Center',
    ExportConfig: 'Export Config',
    AvailableInventory: 'Available inventory management',
    ChannelInventoryShared: 'Channel inventory sharing',
    StoreInventory: 'Available inventory by store',
    ChannelInventoryDistribute: 'Channel available inventory allocation'
  },
  navbar: {
    dashboard: 'Homepage',
    github: 'Github',
    logOut: 'Log out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global size'
  },
  login: {
    title: 'Login Form for OMS',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    verificationCode: 'Verification Code',
    ssoError: 'Your Feishu account has not been mapped to the WMS system account yet, please use the username and password to log in to the business system'
  },
  footer: {
    help: 'help',
    privacy: 'privacy',
    clause: 'clause'
  },
  page: {
    manualSendingResults: 'Manual Sending Results',
    templateType: 'Template Type',
    AddComplianceWarehouse: 'Add Compliance Warehouse',
    VirtualWarehouse: 'Virtual Warehouse',
    AddVirtualWarehouse: 'Add VirtualWarehouse',
    StockTip: '（P.S. 请输入自然数库存）',
    Stock: 'Stock',
    PointInTime: 'Point In Time(Chinese Standard Time)',
    DailyPush: 'Daily Push',
    SelectSKU: 'Select SKU',
    PushTime: 'Push Time',
    PushRecords: 'Push Records',
    PushStockLevels: 'Push Stock Levels',
    StockOfComplianceWarehouse: 'Stock Of Compliance Warehouse',
    ComplianceWarehouse: 'Compliance Warehouse',
    AddRules: 'Add Rules',
    EditRules: 'Edit Rule',
    PushRules: 'Push Rules',
    TimedPush: 'Timed Push(Chinese Standard Time)',
    TotalInventoryOfComplianceWarehouse: 'Total Inventory Of Performance Warehouses',
    AvailableStock: 'Available Stock',
    PushableInventory: 'Pushable Inventory',
    ManualPush: 'Manual Push',
    PushLog: 'Push Log',
    ViewPushRules: 'View Push Rules',
    PlatformVirtualWarehouse: 'Platform Virtual Warehouse',
    PlatformSiteShop: 'Platform/Site/Shop',
    DoNotReceiveMail: 'Do not receive mail',
    DoNotSendQuestionnaire: 'Do not send questionnaire',
    ID: 'ID',
    senderId: 'Sender ID',
    AddScore: 'Add Score',
    MinusScore: 'Minus Score',
    ScoringItem: 'Scoring Item',
    ScoringSubitem: 'Scoring Subitem',
    ProblemScoring: 'Problem Scoring',
    SeriousPenalty: 'SeriousPenalty',
    SuggestionScoring: 'Suggestion Scoring',
    ExcellentScoring: 'Excellent Scoring',
    AddSubtractItem: 'Add Subtract Item',
    AddItem: 'Add Item',
    ScoringMethod: 'Scoring Method',
    isSeriousPenalty: 'Is there a serious penalty',
    TaxpayerIdentificationNumber: 'Taxpayer Identification Number',
    Subordinate: 'Subordinate',
    BatchTransfer: 'Batch Transfer',
    LogisticsChannel: 'Logistics Channel',
    // summer
    required: 'required',
    alreadySel: 'Already selected',
    rowS: 'rows',
    empty: 'Empty',
    fileText: 'Drag files here, or',
    fileTextUpload: 'Click upload',
    OnlyXlsAndXlsxFilesCanBeUploaded: 'Only.xls and.xlsx files can be uploaded',
    UploadFileSizeShouldNotExceed: 'Upload file size should not exceed 1MB!',
    UploadAndImportFiles: 'Upload and import files',
    TheImportDetails: 'The import details',
    setAccountDisplay: 'Set account display',
    inventoryUpload: 'Inventory upload',
    ebayUploadTip: 'Are you sure you want to upload your inventory to EBAY?',
    selectOneData: 'Select at least one valid piece of data',
    /** 公用 */
    LoginFailed: 'Login failed',
    Relogin: 'Re-login',
    HaveBeenLoggedOut:
      'You have been logged out, you can cancel to stay on this page, or log in again',
    PasswordManagement: 'Password management',
    Password: 'Password',
    Newpassword: 'New password',
    ConfirmPassword: 'Confirm password',
    ResetPassword: 'Reset password',
    Character: 'Character',
    Staff: 'Staff',
    phoneNumber: 'Phone Number',
    InformationManagement: 'Information management',
    username: 'Username',
    Email: 'Email',
    Avatar: 'Avatar',
    requestTimeOut: 'The request has timed out. Please try again. ',
    homepage: 'homepage',
    Details: 'Details',
    search: 'Search',
    reset: 'Reset',
    expand: 'Expand',
    hide: 'Hide',
    create: 'Create',
    export: 'Export',
    stockHistoryExport: 'Stock history export',
    startTimeEndTime: 'StartTime-EndTime',
    No: 'No',
    inputPlaceholder: 'please enter',
    inputTemplatetype: 'please enter Templatetype',
    selectPlaceholder: 'please select',
    enabled: 'Enabled',
    disable: 'Disable',
    operate: 'Operate',
    view: 'View',
    modify: 'Modify',
    invalid: 'Invalid',
    createDate: 'Create Date',
    createTime: 'Create Time',
    receiveTime: 'Receive Time',
    finishTime: 'Finish Time',
    handleTime: 'Handle Time',
    createByName: 'Creator',
    warehouse: 'Warehouse',
    Linkman: 'Linkman',
    sure: 'Sure',
    delete: 'Delete',
    add: 'Add',
    import: 'Import',
    templateDownload: 'Template Download',
    tray: 'Tray',
    storageArea: 'Storage Area',
    expectedNum: 'Expected Num',
    expectedBoxNum: 'Expected Box Num',
    close: 'Close',
    Specifications: 'Specifications',
    total: 'Total',
    batchRemove: 'Batch Remove',
    batchCreate: 'Batch Create',
    typeBy: 'Type',
    stopReport: 'Stop Report',
    reportReason: 'Report Reason',
    inputStopReason: 'Please input stop reason',
    status: 'Status',
    purchaseNoteNO: 'purchaseNoteNO',
    associationNoteNO: 'associationNoteNO',
    Prompt: 'Prompt',
    selectTheErrors: 'select the Errors',
    platformcreatetime: 'platform create',
    systemcreatetime: 'system create'
  },
  title: {
    Group: 'Group',
    'ImportSuccess': '导入成功',
    'UploadError': '上传失败请稍后再试!',
    'SizeLimitTip': '请上传{limit}以内的文件',
    'LimitTip': '上传文件个数最多{limit}个!',
    'FileTypeTip': '仅能上传{accept}类型文件',
    History: 'History',
    Update: 'Update',
    Permission: 'Permission',
    back: 'Back',
    Save: 'Save',
    Edit: 'Edit',
    Replace: 'Replace',
    BasicInformation: 'Basic information',
    submit: 'Submit',
    cancel: 'Cancel',
    confirm: 'Confirm',
    ExportDetail: 'Export detail',
    // SKU材料组件管理
    Components: 'Components',
    OtherInformation: 'SKU other information',
    // 平台SKU信息
    Color: 'Color',
    Size: 'Size',
    Yes: 'Yes',
    No: 'No',
    // OMS
    CommodityCategory: 'Commodity level category',
    style: 'style',
    shipmentnumber: 'Shipment number ',
    shippingInfo: 'Shipping Info',
    Orderplatform: 'Order platform',
    Platformsite: 'Platform site',
    PlatformAccount: 'Platform account',
    Amazon: 'Amazon',
    Distributableinventory: 'Distributable inventory',
    DistributableInventory: 'Distributable Inventory',
    Uploadinventory: 'Upload inventory',
    selectainventory: 'Please select at least one upload inventory',
    select: 'Please select at least one',
    ebayAmazon:
      'The number of ebay + Amazon cannot exceed the available inventory',
    OrderType: 'OrderType',
    OrderAll: 'All',
    primeorder: 'prime order',
    Nonprimeorders: 'Non-prime orderse',
    ShippingStatus: 'Shipping Status',
    ordernumber: 'Order number',
    Refresh: 'Refresh',
    filter: 'filter',
    Batchvoid: 'Batch void',
    Batchexport: 'Batch export',
    Batchreview: 'Batch review',
    Batchallocation: 'Batch allocation of orders',
    ordersmanually: 'Synchronize orders manually',
    Manualsync: 'Manual sync',
    Buyername: 'Buyer name',
    BuyerEmail: 'Buyer Email',
    orderamount: 'order amount',
    commodityTax: 'Commodity Tax',
    Shippingaddress: 'Shipping address',
    Partialshipment: 'Partial shipment',
    Shippe: 'Shipped',
    Deliverytime: 'Delivery time',
    Shippingwarehouse: 'Shipping warehouse',
    image: 'image',
    Specification: 'Specification',
    aQuantity: 'Quantity',
    Amount: 'Amount',
    quantityorder: 'quantityorder',
    Freight: 'Freight',
    Currency: 'Currency',
    amountactuallypaid: 'The amount actually paid',
    Paymentdate: 'Payment date',
    Buyer: 'Buyer',
    phone: 'Phone',
    mailbox: 'mailbox',
    destination: 'destination',
    Generationdate: 'Generation date',
    Distributionwarehouse: 'Distribution warehouse',
    cancelorder: 'Are you sure to cancel the order?',
    handsyncorders: 'Are you sure to handsync the order?',
    canceltip:
      'Unable to recover after cancellation. Sure you want to cancel the order?',
    allocateorders: 'Are you sure to allocate orders?',
    batchprocessing: 'Are you sure to take the processing?',
    Tobeallocated: 'To be allocated',
    Assigned: 'Assigned',
    to: 'to',
    orderinfo: 'order information',
    Productinfo: 'Product information',
    CustomerInfo: 'Customer information',
    OrderStatus: 'Order status',
    nooder:
      'There is a detail in the selected order that does not match the batch allocation',
    Noorder:
      'There is a lack of mobile phone number in the selected order. Do you choose to submit after filtering',
    Assigning: 'Assigning',
    Deliverytype: 'Delivery type',
    Selfdelivery: 'Self-delivery',
    FBAdelivery: 'FBA delivery',
    mixing: 'mixing',
    Orderproductinfo: 'Order product information',
    Shippingmethod: 'Shipping method',
    shipmentsnum: 'shipments number',
    Parcelnumber: 'Parcel number',
    Shippingdate: 'Shipping date',
    Carrier: 'Carrier',
    Shippingtype: 'Shippingtypes',
    received: 'Received',
    Invalid: 'Invalid',
    Planning: 'Planning',
    Processing: 'Processing',
    Cancelled: 'Cancelled',
    Complete: 'Complete',
    CompletePartialled: 'CompletePartialled',
    Unfulfillable: 'Unfulfillable',
    Unabledeliver: 'Unable to deliver',
    OutOfDate: 'out of date',
    starttime: 'start time',
    endtime: 'end time',
    TransfertoFBA: 'Transfer to FBA',
    Cancelling: 'Cancelling',
    Cancelleds: 'Cancelled',
    singleenter:
      'Please input multiple numbers in English, separated by (comma)',
    EnterItemID: 'Enter multiple ItemIDs in English, separated by (comma)',
    CancelFBA: 'Cancel FBA shipping',
    number: 'account number',
    platNumber: 'Platform bill number',
    associatedNumber: 'Associated number',
    replenishmentNumber: 'Replenishment Number',
    platInfo: 'Platform information',
    addressInfo: 'Address information',
    orderAmount: 'Order amount',
    date: 'date',
    PlatformSku: 'Platform sku',
    time: 'time',
    Finalrespondent: 'Final respondent',
    Finalhandle: 'Finalhandle',
    Markasreplied: 'Mark as replied',
    Markasreaded: 'Mark as readed',
    readed: 'readed',
    unread: 'unread',
    Abolishing: 'Abolishing',
    Markasunread: 'Mark as unread',
    Markashandled: 'Mark as handled',
    Markasstar: 'Mark as star',
    Unreply: 'Unreply',
    draft: 'draft',
    replied: 'replied',
    errorSend: 'Error send',
    customerID: "Customer's id",
    customerName: "Customer's name",
    latestNews: 'Latest news',
    platformtopic: 'Platform Topic',
    latestnewsTime: 'Latest news Time',
    platform: 'platform',
    nextMessage: 'Next message',
    order: 'Order',
    copy: 'Copy',
    Systemdeliveryinformation: 'System delivery information',
    Platformmessage: 'Platform message',
    Recipientaddress: 'Recipient address',
    Listinginfo: 'listing Info',
    note: 'Note',
    sure: 'Sure',
    title: 'title',
    Havepointswarehouse: 'Have points warehouse',
    content: 'Content',
    operator: 'Operator',
    classify: 'Classify',
    Presales: 'Pre sales',
    Aftersalesservice: 'After sales service',
    Insalesservice: 'In sales service',
    Customersevice: 'Customer sevice',
    Untreated: 'Untreated',
    treated: 'Untreated',
    Autonextmessage: 'Auto next message',
    send: 'Send',
    EnterSend: 'ctrl_enter send',
    topic: 'Topic',
    Addtemplate: 'Add tempalte',
    classifyManage: 'classify Manage',
    Templateclassify: 'Template classify',
    Templatetitle: 'Template title',
    Templatecontent: 'Template content',
    AddOrEditClassify: 'Add Or Edit Classify',
    Attachedfile: 'Attached file',
    Receivedtime: 'Received time',
    today: 'today',
    yesterday: 'yesterday',
    Inthreedays: 'In three days',
    Insevendays: 'In seven days',
    Inamonth: 'In a month',
    emailsmanually: 'emails manually',
    site: 'Site',
    shopName: 'shopName',
    amount: 'amount',
    HandleTimeOut: 'Handle TimeOut',
    ProductMismatch: 'Product Mismatch',
    ShippingConfigurationError: 'Shipping Configuration Error',
    InsufficientInventory: 'Insufficient Inventory',
    PartOfDistribution: 'Part of  distribution',
    LastTrackingFailed: ' last tracking failed',
    NotApproved: 'Not approved',
    AbnormalDistribution: 'Abnormal Distribution',
    ToBeReleased: 'To be released',
    ToAudit: 'To Audit',
    Gettracefailed: 'Get trace failed',
    Release: 'Release',
    Handle: 'Handle',
    Goback: 'Goback',
    Pushagain: 'Push again',
    abnormal: 'abnormal',
    distributionsum: 'distribution sum',
    Requiedinventory: 'Requied inventory',
    Canuseinventory: 'Can use inventory',
    Operationlog: 'Operation log',
    OperationMethod: 'Operation Method',
    Errorpackage: 'Error package',
    create: 'create',
    normal: 'normal',
    waitingship: 'waitingship',
    lessship: 'lessship',
    allocateSure: 'allocate Sure',
    BatchDistributionWarehouse: 'Batch Distribution Warehouse',
    PartOfDistributed: 'Part Of Distributed',
    TheerrorPackages: 'The error packages',
    Aftersalesserviceorder: 'After sales service order',
    Expecteddateofshipment: 'Expected date of shipment',
    platformpaymenttime: 'platform payment',
    cannotgetoutwarehouse: 'cannot get out warehouse',
    handleMatched: 'handle Matched',
    changeSku: 'change Sku',
    createRMA: 'create RMA',
    Notice: 'Are you sure to modify the submitted current data?',
    everySKu: 'Create each SKU',
    savefirst: 'Please save your changes first',
    createfail: 'Create a failure',
    operatesuccess: 'operate success',
    copysuccess: 'copy success',
    cannotallocation:
      'If there are unqualified details in the selected order, whether to select filter and submit',
    Add: 'Add',
    Paymenttime: 'Payment time',
    Generationtime: 'Generation time',
    Inventorytostaytuned: 'Inventory to staytuned',
    addresser: 'addresser',
    toTuned: 'to Tuned',
    wmsOrderNumber: 'Outbound ordernumber',
    // 筛选
    orderPriceStart: 'order Price Start',
    orderPriceEnd: 'order Price End',
    country: 'country',
    city: 'city',
    stateRegion: 'state Region',
    systemCreateDateStart: 'system Create Date Start',
    systemCreateDateEnd: 'system Create Date End',
    paymentsDateStart: 'payments Date Start',
    paymentsDateEnd: 'payments Date End',
    orderCreateDateStart: 'order Create Date Start',
    orderCreateDateEnd: 'order Create Date End',
    sendDateStart: 'send Date Start',
    sendDateEnd: 'send Date End',
    ProblemMentioned: 'Problem mentioned',
    TicketStatus: 'Ticket status',
    platforms: 'Platform',
    SellingAccount: 'Selling Account',
    Ticketno: 'Ticket no.',
    OrderCreationMethod: 'Order Creation Method',
    TicketDate: 'Ticket Date',
    Productrank: 'Position',
    keyword: 'Keyword',
    Unanswered: 'Unanswered',
    Answered: 'Answered',
    Draft: 'Draft',
    Failedtosent: 'Failed to sent',
    Remindfollow: 'Remind to follow-up',
    Subject: 'Subject',
    Productinformation: "Product's information",
    Agent: 'Agent',
    Respondingtime: 'Responding time',
    FirstEmailTime: 'First email time',
    LatestLetterTime: 'Latest letter time',
    LastReplyTime: 'Last reply time',
    ClosedTime: 'Closed time',
    Closepage: 'Close page',
    TimeType: 'Time type',
    Allmessages: ' All messages',
    Show: 'Show',
    Product: 'Product',
    Insert: 'Insert',
    Customerid: "Customer's id",
    Account: 'Account',
    Agentname: "Agent's name",
    Template: 'Template',
    Producttitle: "Product's title",
    Preview: 'Preview',
    Quantity: 'Quantity',
    FulfillmentMethod: 'Fulfillment Method',
    Trackingnumber: 'Tracking number',
    Shippingtime: 'Shipping time',
    RMA: '关联售后单',
    RelatedOrder: 'Related order',
    Note: 'Note',
    Transferto: 'Transfer to',
    Remind: 'Remind',
    Blockedmail: 'Blocked e-mail',
    Name: 'Name',
    emailaddress: 'E-mail address',
    Blockedtime: 'Blocked time',
    Closedtickets: 'Closed tickets',
    Sentmessages: 'Sent messages',
    Markedasread: 'Marked as read',
    Averagerespondingtime: 'Average response time for first reply',
    Longestrespondingtime: 'Maximum response time',
    Shortestrespondingtime: 'Average response time',
    Averageresponsetime: 'Average processing time',
    Transferringtime: 'Transferring time',
    Transferredtime: 'Transferred time',
    PerfectWorkOrder: 'Perfect Work Order',
    PerfectWorkOrderRate: 'Perfect Work Order Rate',
    BadReviewWorkOrder: 'Bad Work Order',
    Shippingwarehouses: 'Shipping warehouse',
    Reviewed: 'Reviewed',
    Problemmentioned: 'Problem mentioned',
    Sentmessagestime: 'Sent messages',
    Respondingtimes: 'Responding time',
    SetUpRMA: 'Set up RMA',
    All: 'All',
    Awaitingapproval: 'Awaiting approval',
    Unshippeds: 'Unshipped',
    Unreceived: 'Unreceived',
    Received: 'Received',
    Deliveredreceived: 'Delivered not received',
    Refunded: 'Refunded',
    Refused: 'Refused',
    Failedrefund: 'Failed to refund',
    Canceled: 'Canceled',
    Partiallyreceived: 'Partially received',
    Refunding: 'Refunding',
    Transferring: 'Transferring',
    Moreinformation: 'More information',
    Refundinformation: 'Refund information',
    Buyerpessage: "Buyer's pessage",
    Buyerpicture: "Buyer's picture",
    stytemNote: 'Note',
    Attachpicture: 'Attach picture',
    Submit: 'Submit',
    Orderamount: 'Order amount',
    Additionalrefund: 'Additional refund',
    Amounttorefund: 'Amount to refund',
    Totalrefund: 'Total refund',
    Customizedamount: 'Customized amount',
    Buyermessage: "Buyer's message",
    Returnexchange: 'Return exchange',
    Trackingforreturn: 'Tracking for return',
    RMAid: 'RMA ID',
    Closed: 'Closed',
    Addfirstlevellabel: 'Add a first-level label',
    Addsecondarylabel: 'Add secondary label',
    Addthreelevellabel: 'Add a three-level labe',
    NewManageLabe: 'New/Manage Labe',
    finished: 'finished',
    Unfinished: 'Unfinished',
    NewProduct: 'New product',
    Customerids: 'Customer ID/Customer Name',
    Notshipped: 'Not shipped',
    Subjectmail: 'Subject mail',
    MessageID: 'Message ID',
    Closeworkorder: 'Close work order',
    administrator: 'administrator',
    distribute: 'distribute',
    Remove: 'Remove',
    assignBU: 'AssignBU',
    EmailAccount: 'Email account',
    underreview: 'under review',
    Usetemplates: 'Use templates',
    Deprecatedtemplates: 'Deprecated templates',
    Addtemplates: 'Add template',
    Modifythetemplate: 'Modify the template',
    Submitreview: 'Submit review',
    addcategory: 'Add category',
    username: 'Name',
    selectionperiod: 'selection period',
    types: 'Type',
    SaveAndSend: 'Save and send',
    day: 'day',
    alreadyexists: 'The current work order already exists',
    continueeply: 'In processing, do you continue to reply!',
    Continuetoreply: 'Continue to reply',
    Theoperation: 'The operation is successful, automatically jump to the next one',
    operationfailed: 'operation failed',
    Syncdate: 'Sync date',
    Pullmailmanually: 'Pull mail manually',
    Producttitles: 'Product title',
    Afterentering: 'Input Order No., Press Enter to Continue',
    Bypercentage: 'percentage',
    Orderexception: 'Order exception',
    ShippingWarehouse: 'Shipping warehouse',
    SendingTime: 'Sending time',
    SentTimeMustBeLaterThanCreationTime: 'Sent time must be later than the creation time',
    SentStatus: 'Sent status',
    EmailAddress: 'Email address',
    QueryResultExport: 'Query result export',
    ManualImport: 'Manual import',
    deliveryAgeing: 'Delivery Time',
    MCFCreationFailed: 'MCF creation failed',
    FBAHasNotShippedDueDate: 'FBA has not shipped due date',
    OutOfStockSubCategories: 'Out of stock sub-categories'
  },
  topic: {
    RMAType: 'RMA Type',
    RefundPoints: 'Whether to refund points',
    RMAWay: 'RMA Way',
    Refunds: 'Return to get refund',
    OnlyRefund: 'Only refund',
    Manual: 'Manual',
    System: 'Customer Requested',
    ReturnOrderNumber: 'Return number',
    PlatformOrderNumber: 'Platform number',
    ReturnWarehouse: 'Return warehouse',
    Reviewtime: 'Review time',
    Paymenttime: 'Refund time',
    Reviewer: 'Reviewer',
    Moneymaker: 'Refunder',
    productname: 'product name',
    Numberofrefunds: 'Quantity',
    reasonforreturn: 'Reason for Return',
    Refundamount: 'Refund amount',
    Receiptstatus: 'Receipt status',
    Refundmethod: 'Refund method',
    generate: 'generate',
    Submissiontime: 'Submission time',
    refunds: 'Refunds',
    review: 'Review',
    Confirmreceipt: 'Confirm receipt',
    Reasonforfailure: 'Reason for failure',
    Refundtypeoperation: 'Refund type operation',
    Fullrefund: 'Full refund',
    Partialrefund: 'Partial refund',
    BuyerID: 'Buyer ID',
    Taxes: 'Taxes',
    Shippingfeerefund: 'Shipping fee refund',
    Promotionaldiscount: 'Promotional discount',
    Additionalrefund: 'Additional refund',
    Expeditedshippingtax: 'Expedited shipping tax',
    Productexpeditedshipping: 'Product expedited shipping',
    Producttaxes: 'Product taxes',
    Productfee: 'Product fee',
    Orderrefund: 'Order refund',
    Agreeandrefund: 'Agree to return and refund',
    fullrefund: 'full refund',
    Refuse: 'Refuse',
    Typerefund: 'Type of refund',
    Refundtime: 'Refund time',
    Refunder: 'Refunder',
    RMAstatus: 'RMA status',
    Refundonly: 'Refund only (not shipped)',
    Refundshipped: 'Refund only (shipped)',
    logisticsmethod: 'Please choose the logistics method',
    Agreetoreturn: 'Agree to return',
    Additionaltax: 'Additional shipping tax',
    Extrashipping: 'Extra shipping',
    Expeditedshipping: 'Expedited shipping',
    Expeditedfreighttax: 'Expedited freight tax',
    Ticktoshipping: 'Tick ​​to represent the return shipping',
    Confirmdelivery: 'Confirm delivery',
    logisticsprovider: 'Please enter the logistics provider',
    trackingnumber: 'Please enter the tracking number',
    Batchcancel: 'Batch cancel',
    Partial: 'Partial refund pending buyer agreement',
    refuses: 'Buyer refuses partial refund',
    BulkRefund: 'Bulk Refund'
  },
  order: {
    Address: 'Address',
    address: 'Address',
    Systemordernumber: 'System order number',
    Sourcenumber: 'Source number',
    Platformordernumber: 'Platform order number',
    ApprovalStatus: 'Approval Status',
    Systemordernumbers: 'System order number',
    fbaPlatform: 'Platform',
    Fulfillmentmethod: 'Fulfillment method',
    Carrier: 'Carrier',
    fbaSite: 'Site',
    Sellingaccount: 'Selling account',
    Paymentstatus: 'Payment status',
    Shippingnotes: 'Shipping notes',
    Orderstatus: 'Order status',
    Systemreviewed: 'System reviewed',
    Shippingstatus: 'Shipping status',
    Receipt: 'Receipt',
    Setupby: 'Set up by',
    Awaitingreview: 'Awaiting review',
    Rejected: 'Rejected',
    Unpaid: 'Rejected',
    Paid: 'Paid',
    Pending: 'Pending',
    Pendingshipment: 'Pending shipment',
    completed: 'completed',
    Shipped: 'Shipped',
    Arrived: 'Arrived',
    Cancel: 'Cancel',
    Platformfulfilled: 'Platform fulfilled',
    Sellerfulfilled: 'Seller fulfilled',
    UTCtime: 'UTC time',
    Orderinformation: 'Order information',
    Receiptinformation: "Receipt's information",
    SystemID: 'System ID',
    Currency: 'Currency',
    Orderamount: 'Order amount',
    Timezone: 'Time zone',
    Purchasetime: 'Purchase time',
    Updatetime: 'Update time',
    Buyername: "Buyer's name",
    Buyeremail: "Buyer's e-mail",
    reAddress: 'Address',
    Zipcode: 'Zip code',
    Country: 'Country',
    ReturnRefund: 'Return/Refund',
    Productinformation: 'Product information',
    Totalamount: 'Total amount',
    Trackingnumber: 'Tracking number',
    Chargedatails: 'Charge datails',
    Type: 'Type',
    SubtotalRMB: 'Subtotal (RMB)',
    ExchangerateUSD: 'Exchange rate(RMB to USD)',
    Subtotal: 'Subtotal (USD)',
    Exchangerate: 'Exchange rate(USD to RMB)',
    Note: 'Note',
    Buyerinformation: "Buyer's information",
    States: 'States',
    Orderinformations: 'Order information',
    Productinformations: 'Product information',
    Quantityenter: 'Quantity(Double click to enter)',
    KOLorder: ' KOL order',
    Statesd: 'States',
    RMB: 'RMB',
    Log: 'Log',
    types: 'Type',
    Original: 'Original',
    Changed: 'Changed',
    OperationTime: 'Operation Time',
    LocalTime: 'Local Time',
    Bejingtime: 'Bejing time',
    Detail: 'Detail',
    Replacement: 'Replacement',
    AddSUK: 'Add SKU',
    Pleaseshortname: 'Please enter the short name',
    system: 'System',
    source: 'Source',
    other: 'Other',
    KOLAddress: 'Select the KOL delivery address'
  },
  tips: {
    GreaterThanMin: 'The push inventory level cannot be greater than the Inventory Minimum',
    GreaterThan0: 'Enter Value Greater Than 0',
    ValueLesser: 'Enter Value Lesser Than Original',
    LesserThan20: 'Enter Value Lesser Than 20',
    EnterOriginal: 'Enter Value Lesser Than Original',
    Product: 'Product Information Cannot be Empty',
    ThanOriginal: 'Enter Value Lesser Than Original and Cannot be Empty',
    GreaterThan: 'Enter Value Greater Than 0',
    Lesser: 'Enter Value Lesser Than 100%',
    Reinstated: 'Cannot be Reinstated, Confirm?',
    ConfirmReceived: 'Confirm Received?',
    ReceivingAlert: 'Receiving Alert',
    Cancellation: 'Cancellation Alert',
    FalilureAlert: 'Reason for Refund Falilure Alert',
    Falilure: 'Reason for Refund Falilure',
    FalilureXML: 'Reason for Refund Falilure XML',
    Sent: 'Sent',
    SnallerThan: 'Attach File Snaller Than 3M',
    Copied: 'Copied',
    LesserThan: 'Enter Value Lesser Than 30',
    notEmpty: 'Cannot be Empty, Please Try Again',
    GreaterThan30: 'Enter Value Greater Than 30',
    Selectleast: 'Please Select at least One',
    Successful: 'Successful',
    Faileds: 'Failed',
    EmailRetrieved: 'E-mail Retrieved',
    Failed: 'Failed',
    Changed: 'Changed',
    failed: 'Failed',
    Deleted: 'Deleted',
    faileds: 'Failed',
    enterContent: 'Please enter content',
    Failedsend: 'Failed to send',
    Salespath: 'Sales path',
    Oftenaging: 'Normal aging',
    Readytoday: 'To be shipped today',
    Deliverytimeout: 'Delivery timeout',
    CorrectedSKU: 'Corrected SKU',
    Pleasechooseplatform: 'Please choose a platform',
    entercountry: 'Please enter country',
    entercity: 'Please enter the city',
    enterdistrict: 'Please enter a state or district',
    Modifycustomerinfo: 'Modify customer information',
    Incomplete: 'Incomplete customer information',
    ReturnWarehouse: 'Return warehouse',
    Sensitivewords: 'Sensitive words',
    Addsensitivewords: 'Add sensitive words',
    Batchuse: 'Batch use',
    Noreply: 'No reply',
    Confirmbatchuse: 'Confirm batch use',
    ResponseTime: 'Response time',
    Fraudulent: 'Fraudulent order',
    operation: ' Operator',
    refundAmountTips: 'Please make sure the refund amount is correct',
    labelCN: 'Please enter the Chinese label name',
    labelEN: 'Please enter the corresponding English label name',
    Onlysupport: 'Only support Purchase Order number',
    PurchaseOrder: 'Platform order number / Purchase Order',
    enterPurchaseOrder: 'Please enter Purchase Order',
    TransactionID: 'Transaction ID modifiy',
    SKUModifiy: 'sku modifiy'
  },
  export: {
    download: 'Download',
    exportDescription: 'Export Description',
    successTip: 'The export is successful, please go to'
  },
  // 手工建单
  SimpleManual: {
    QuantityCannotBeEmpty: 'Quantity cannot be empty',
    ProductImage: 'Product image',
    PriceEmpty: 'The product amount in the order is 0. Please modify the amount'
  }
}
