import axios, { CanceledError } from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import i18n from '@/lang' // internationalization
import qs from 'qs'

const service = axios.create({
  // baseURL: 'http://192.168.1.61:8082',
  baseURL: process.env.VUE_APP_RIGHTS_API,
  timeout: 30000,
  validateStatus(status) {
    return true
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // sso系统标识
    config.headers['x-oauth2-app'] = 'oms'
    config.headers['x-sso-auth'] = store.getters.jwtToken || ''
    config.headers['x-referer'] = location.href
    if (store.getters.token) {
      config.headers['authorization'] = 'Bearer ' + store.getters.token
    } else {
      config.headers['authorization'] = 'Basic d2ViQXBwOndlYkFwcA=='
    }
    if (store.getters.tenantId && store.getters.warehouse && store.getters.name) {
      config.headers['x-userinfo-header'] = `${2}-${store.getters.userId}-${store.getters.name}-${store.getters.warehouse || 1}`
    }
    if (config.method === 'get') {
      if (config.data) {
        config.url += '?' + qs.stringify(config.data, { arrayFormat: 'comma' })
      } else {
        // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
        config.paramsSerializer = function(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    }
    config.headers['x-language-header'] = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 判断后端返回的code码是否为0 如果不为0 异常处理
    if (res.code && res.code !== 0) {
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        console.log(2)
        MessageBox.confirm(i18n.t('page.HaveBeenLoggedOut'), i18n.t('page.LoginFailed'), {
          confirmButtonText: i18n.t('page.Relogin'),
          cancelButtonText: i18n.t('title.cancel'),
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      } else if (res.code === 401) {
        // 未授权的请求,重定向到单点登录页面
        store.dispatch('user/resetToken').then(() => {
          location.replace(`${process.env.VUE_APP_SSO_REDIRECT_URL}?_fromApp=auth-server&redirect_uri=${location.href}`)
        })
      } else {
        // if (res.status === 500) {
        //   Message({
        //     message: res.message || 'Error',
        //     dangerouslyUseHTMLString: true,
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   return Promise.reject(new Error(res.message || 'Error'))
        // }
        // if (res.success === false) {
        //   Message({
        //     message: res.error || 'Error',
        //     dangerouslyUseHTMLString: true,
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   return Promise.reject(new Error(res.error || 'Error'))
        // }
        Message({
          message: res.error || res.msg || 'Error',
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.msg || 'Error'))
      }
    } else {
      // jwt_token需要刷新，重定向到接口返回路径
      if (res.responseCode === '302') {
        store.dispatch('user/resetToken').then(() => {
          location.replace(res.data.redirectPath)
        })
      }
      return res
    }
  },
  error => {
    if (error instanceof CanceledError) {
      return
    }
    // console.log(error.message)
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
