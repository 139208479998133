<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 1
    }
  },
  render(h, context) {
    const { icon, title, level } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<svg-icon icon-class={icon}/>)
    }

    if (title) {
      if (title.length > 14) {
        vnodes.push(
          <el-tooltip slot='title' placement='right' open-delay={200} content={title}>
            <div style={{ width: level === 1 ? '70%' : '100%' }} class='title'>{(title)}</div>
          </el-tooltip>
        )
      } else {
        vnodes.push(
          <div slot='title' class='title'>{(title)}</div>
        )
      }
    }
    return vnodes
  }
}
</script>

<style scoped>
.title {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
