import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { setTokenByKey, JWT_TOKEN, WG_OAUTH_REFRESH, WG_OAUTH, setToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import { asyncRoutes } from '@/router/index'
import { getUserMenus, getUserMenuRes } from '@/api/user'
import { omit } from 'lodash'
import { Message } from 'element-ui'
import i18n from '@/lang'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/auth-redirect', '/selection', '/selection/details'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  // 设置页面Title
  document.title = getPageTitle(to.meta.title)
  // sso token
  const { jwt_token, wg_oauth_refresh } = to.query
  if (jwt_token) {
    // iframe的token传参字段为token，sso传参字段为wg_oauth
    const token = to.query.token || to.query.wg_oauth
    if (!token) {
      Message({
        message: i18n.t('login.ssoError'),
        type: 'error',
        duration: 3 * 1000
      })
      let redirect_uri = location.href.split('?')[0]
      const arr = []
      for (const [key, value] of Object.entries(to.query)) {
        if (key && key !== JWT_TOKEN) arr.push(`${key}=${value}`)
      }
      arr.length && (redirect_uri += `?${arr.join('&')}`)
      setTimeout(() => {
        location.href = `${process.env.VUE_APP_RIGHTS_API}auth/loginPage?redirect_uri=${redirect_uri}`
      }, 3000)
      return
    }
    setToken(token)
    setTokenByKey(JWT_TOKEN, jwt_token)
    setTokenByKey(WG_OAUTH_REFRESH, wg_oauth_refresh)
    store.commit('user/SET_JWT_TOKEN', jwt_token)
    store.commit('user/SET_REFRESH_TOKEN', wg_oauth_refresh)
    store.commit('user/SET_TOKEN', token)

    const query = omit(to.query, JWT_TOKEN, WG_OAUTH_REFRESH, WG_OAUTH)
    next({ path: to.path, query, replace: true })
  } else {
    if (store.getters.userId) {
      if (whiteList.indexOf(to.path) !== -1 || to.path.includes('/iframe/')) {
        next()
        NProgress.done()
      } else {
        const permissionRoutes = store.getters.permission_routes
        const { length, last = length - 1 } = permissionRoutes
        const hasMenu = permissionRoutes && permissionRoutes[last] && permissionRoutes[last].path === '*'
        if (hasMenu) {
          // 动态获取菜单资源
          const permissionRoutes = store.getters.permission_routes
          // 循环遍历获取到菜单id
          const menuId = getMenuId(permissionRoutes, to.name)
          if (menuId) {
            const { res = [], skipAuth = false } = await getUserMenuRes(menuId)
            const menuCodes = res.map(item => item.code)
            await store.dispatch('permission/updataPermissions', menuCodes)
            await store.dispatch('permission/setSkipAuth', skipAuth)
          }

          next()
        } else {
          await store.dispatch('user/resetToken')
          location.href = `${process.env.VUE_APP_SSO_REDIRECT_URL}?_fromApp=auth-server&redirect_uri=${location.href}`
          return
        }
      }
    } else {
      try {
        await store.dispatch('user/getInfo')
        const { menus = [] } = await getUserMenus()
        const accessRoutes = await store.dispatch('permission/createRoutes', menus)
        router.addRoutes(accessRoutes)
        next({ ...to, replace: true })
      } catch (error) {
        console.log(error)
      }
    }
  }
})

let menuId
function getMenuId(routes, menuName) {
  routes.forEach(route => {
    if (route.children) {
      getMenuId(route.children, menuName)
    } else {
      if (route.name === menuName) {
        menuId = route.id
      }
    }
  })
  return menuId
}

router.afterEach((to) => {
  NProgress.done()
})
