import { StatusZH } from './modules/self-order'
export default {
  ...StatusZH,
  route: {
    SendEmail: '发起邮件工单',
    LogisticsProviderMapping: '物流商映射',
    ComplianceWarehouseConfig: '履约仓配置',
    SellableInventory: '可售库存管理',
    AutoPushRules: '自动推送规则管理',
    VirtualWarehouseConfig: '平台虚拟仓配置',
    InventoryVisualization: '库存可视化管理',
    SheInReturnManage: 'SheIn退货退款',
    WalmartDetails: '退货退款详情',
    WalmartAddedRMA: '新增Walmart退货单',
    WalmartRefunds: 'walmart退货退款',
    WalmartReturnManage: 'Walmart退货退款',
    AmazonAdded: '新增Amazon退货单',
    Refunds: '退款',
    EbayRefunds: '退款',
    Review: '审核',
    EbayDetails: '退货退款详情',
    AddedRMA: '新增eBay退货单',
    EbayReturnManage: 'ebay退货退款',
    RMADetail: '退货退款详情',
    RMAManage: '售后管理',
    ReturnManage: 'Amazon退货退款',
    OrderManage: '订单中心',
    SelfDeliveryOrder: '自发货订单',
    SelfDeliveryLogisticsInformation: '自发货物流信息',
    CustomerInformation: '客户信息',
    OrderCenterManage: '订单管理',
    CreateOrder: '补单',
    OrderManageDetail: '订单详情',
    SimpleManual: '手工建单',
    SystemManual: '系统建单',
    PlatformPlanningInventory: '平台规划库存',
    PlatformOrder: '平台订单',
    dashboard: '首页',
    BasicData: '基础类型',
    BasicInformation: '基础信息',
    Company: '公司信息',
    StaffInformation: '员工信息',
    Department: '部门管理',
    Platform: '平台管理',
    Site: '站点管理',
    Blurb: '商品信息',
    Category: '类别管理',
    OneCategory: '一级类别',
    TowCategory: '二级类别',
    ThreeCategory: '三级类别',
    Brand: '品牌管理',
    Style: 'Style管理',
    SkuMaterialComponent: 'SKU材料组件管理',
    MaterialInformation: '材料信息',
    Color: 'COLOR管理',
    Size: 'SIZE管理',
    Box: '盒子管理',
    Case: '箱规管理',
    SkuOtherInformation: 'SKU其他信息管理',
    Sku: 'SKU管理',
    SkuPlatform: '平台SKU管理',
    Warehouse: '仓库管理',
    Storage: '仓库管理',
    StorageArea: '储区管理',
    Shelf: '货架管理',
    Container: '容器管理',
    Location: '库位管理',
    WarehousePlatform: '月台管理',
    JobGroup: '作业组管理',
    ThirdParty: '第三方信息',
    Supplier: '供应商管理',
    Forwarder: '货代管理',
    Added: '编辑',
    Details: '详情',
    Rights: '权限',
    Divisional: '部门管理',
    Menu: '菜单管理',
    Role: '角色管理',
    User: '用户管理',
    Profile: '个人中心',
    StockIn: '入库作业',
    Harvest: '收货单列表',
    TestList: '检测单列表',
    InboundOrder: '入库单列表',
    ShelfTask: '上架任务',
    returnsReceipt: '退货入库',
    Versions: '版本管理',
    AdminVersions: 'OMS版本管理',
    PdaVersions: 'PDA版本管理',
    Shipping: '发运管理',
    PickingTask: '拣货任务',
    StockOut: '出库作业',
    SalesOutbound: '销售出库',
    ShipManagement: 'shipment管理',
    ShopShipment: '门店shipment',
    AmazonShipment: '亚马逊shipment',
    CreateShipment: '生成shipment',
    GeneratShipment: '创建shipment计划',
    ShipmentDetails: 'shipment详情',
    Transport: '转发运',
    AllotContainer: '分配货柜',
    OutboundDetails: '添加出库明细',
    ShipmentLabel: '添加标签',
    InHouseWork: '库内作业',
    CheckManager: '盘点管理',
    CheckDetail: '盘点详情',
    CheckReview: '盘点复核',
    InSurplus: '盘盈管理',
    CheckLose: '盘亏管理',
    MoveManager: '移库管理',
    SampleManager: '样品管理',
    AttrManage: '属性管理',
    AttrSettingManage: '配置属性管理',
    Tactics: '配置策略管理',
    stockManage: '库存管理',
    stockManageList: '库存列表',
    stockAllot: '库存分配',
    stockOutAllot: '调拨出库',
    stockOutAllotAdd: '调拨出库新增',
    stockOutAllotDetail: '调拨出库详情',
    stockOutAllotBatchAdd: '调拨批量新增',
    stockInQuickly: '快速入库',
    stockOutAbnormal: '出库异常处理',
    stopStockOut: '终止出库处理',
    pickDiff: '拣货差异处理',
    ImportFile: '文件导出',
    SystemManage: '系统管理',
    DataDictionary: '数据字典',
    BasicDate: '基础数据',
    PickingTasks: '拣货任务',
    ShippingInfo: '发运信息',
    ReviewPackaging: '复核包装',
    wavePicking: '波次管理',
    wavePickingDetails: '波次详情',
    allotPickTask: '分配拣货任务',
    systemTools: '系统工具',
    boxInfoImport: '包装盒信息导入',
    ShoeLabelVerification: '鞋标验证',
    EbayAllocateInventory: 'Ebay分配库存',
    ServiceManage: '客服管理',
    BuyerMessage: '买家消息',
    PlatformMessage: '平台消息',
    EmailTemplate: '邮件模板',
    EbayServiceManage: 'Ebay客服管理',
    AmazonServiceManage: '亚马逊客服管理',
    BuyerEmail: '买家邮件',
    PlatformEmail: '平台邮件',
    BuyeremailInfo: '邮件详情',
    PlatformemailInfo: '邮件详情',
    EmailManage: '邮件管理',
    EbayBadReview: 'Ebay差评管理',
    AssignBU: '分配BU',
    Statistics: '工单数据统计',
    StatisticsDetails: '个人数据统计详情',
    sortMange: '分类配置',
    WalmartServiceManage: 'Walmart客服管理',
    WalmartBuyerEmail: '买家邮件',
    WalmartPlatformEmail: '平台邮件',
    MessageReply: '消息回复',
    AmazonMessageReply: '消息回复',
    WalmartMessageReply: '消息回复',
    ShopifyServiceManage: 'Shopify客服管理',
    ShopifyBuyerEmail: '买家邮件',
    ShopifyBlockMail: '屏蔽邮箱',
    ShopifyMessageReply: '消息回复',
    BadReviewManage: '亚马逊中差评管理',
    CommentManage: '评论管理',
    StyleStatistics: 'style统计',
    SkuStatistics: 'sku统计',
    PersonalStatistics: '个人统计',
    SensitiveWords: '敏感词管理',
    BuyerMailMessageList: '买家邮件/消息',
    BlockEmails: '屏蔽邮箱',
    BuyerMailMessage: '邮件/消息',
    BuyerMessageReply: '消息回复',
    ShopifyDetails: '退货退款详情',
    ShopifyAddedRMA: '新增Shopify退货单',
    ShopifyReturnManage: 'Shopify退货退款',
    ShopifyRefunds: 'Shopify退货退款',
    TagManage: '标签管理',
    CommentTagManage: '评论管理',
    QuestionnaireWorkOrderManage: '问卷工单管理',
    WorkOrderQualityInspection: '工单质检管理',
    EscalationManage: '申诉管理',
    QualityInspectionLog: '质检日志',
    CustomerService: '人员配置',
    ScoreRules: '评分规则配置',
    CommentStatistics: '评论管理统计',
    PerformanceStatistics: '绩效统计',
    PerformanceField: '绩效字段',
    PerformanceRules: '绩效规则',
    PerformanceRankings: '绩效排名',
    PerformanceDetails: '绩效明细',
    InputIndicator: '录入指标',
    add: '新增',
    StandwebsiteReturnManage: 'standwebsite退货退款',
    StandwebsiteRefunds: 'standwebsite退货退款',
    StandwebsiteAddedRMA: '新增standwebsite退货单',
    StandwebsiteDetails: '退货退款详情',
    ExportManage: '导出管理',
    ExportInfo: '下载中心',
    ExportConfig: '导出配置',
    AvailableInventory: '可售库存管理',
    ChannelInventoryShared: '渠道库存共享',
    StoreInventory: '各店铺可售库存',
    ChannelInventoryDistribute: '渠道可售库存分配'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: 'OMS系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    verificationCode: '验证码',
    ssoError: '您的飞书账号暂未与WMS系统账号映射，请使用用户名密码登录业务系统'
  },
  footer: {
    help: '帮助',
    privacy: '隐私',
    clause: '条款'
  },
  page: {
    timedSendLabel: '定时发送时间(UTC时间)',
    timedSend: '定时发送',
    sendingAccount: '发件账号',
    mainTemplate: '正文模板',
    sendMailUploadTitle: `添加附件（不超过{size}）`,
    searchTopicTemplateTip: '搜索选择主题模板',
    manualSendingResults: '手工发信结果',
    templateType: '模板类型',
    notContinuous: '{warehouse}仓库存量在0到正无穷上不连续',
    allWarehouseTip: '总库存条件与分仓库存条件矛盾，不可提交',
    inputTip: '请完善信息',
    AddComplianceWarehouse: '新增履约仓',
    VirtualWarehouse: '虚拟仓',
    AddVirtualWarehouse: '新增虚拟仓',
    StockTip: '（P.S. 请输入自然数库存）',
    Stock: '库存',
    PointInTime: '时间节点（北京时间）',
    DailyPush: '每日定时推送',
    SelectSKU: '选择SKU',
    PushTime: '推送时间',
    PushRecords: '推送记录',
    PushStockLevels: '推送库存量',
    StockOfComplianceWarehouse: '履约仓库存量',
    ComplianceWarehouse: '履约仓',
    AddRules: '新增规则',
    EditRules: '编辑规则',
    PushRules: '推送规则',
    TimedPush: '定时推送(北京时间)',
    TotalInventoryOfComplianceWarehouse: '履约仓总库存',
    AvailableStock: '平台可售库存',
    PushableInventory: '可推送库存',
    ManualPush: '手动推送',
    PushLog: '推送日志',
    ViewPushRules: '查看推送规则',
    PlatformVirtualWarehouse: '平台虚拟仓',
    PlatformSiteShop: '平台/站点/店铺',
    DoNotReceiveMail: '不收邮件',
    DoNotSendQuestionnaire: '不发问卷',
    ID: 'ID',
    senderId: '发件人ID',
    ProblemMinusScoring: 'Problem减分',
    SuggestionMinusScoring: 'Suggestion减分',
    ExcellentMinusScoring: 'Excellent减分',
    ProblemAddScoring: 'Problem加分',
    SuggestionAddScoring: 'Suggestion加分',
    ExcellentAddScoring: 'Excellent加分',
    AddScore: '加分数',
    MinusScore: '减分数',
    ScoringItem: '评分项',
    ScoringSubitem: '评分子项',
    ProblemScoring: 'Problem计分',
    SeriousPenalty: '严重减分项',
    SuggestionScoring: 'Suggestion计分',
    ExcellentScoring: 'Excellent计分',
    AddSubtractItem: '新增减分项',
    AddItem: '新增加分项',
    EditScoringItem: '修改评分项',
    ScoringMethod: '评分方式',
    isSeriousPenalty: '是否严重减分项',
    TaxpayerIdentificationNumber: '纳税人识别号',
    Subordinate: '所属地',
    BatchTransfer: '批量流转',
    LogisticsChannel: '物流渠道',
    // summer
    required: '必填',
    alreadySel: '已选择',
    rowS: '项',
    empty: '清空',
    fileText: '将文件拖到此处，或',
    fileTextUpload: '点击上传',
    OnlyXlsAndXlsxFilesCanBeUploaded: '只能上传.xls与.xlsx文件',
    UploadFileSizeShouldNotExceed: '上传文件大小不能超过 1MB!',
    UploadAndImportFiles: '上传导入文件',
    TheImportDetails: '导入详情',
    setAccountDisplay: '设置账号显示',
    inventoryUpload: '库存上传',
    ebayUploadTip: '您确定要上传库存至EBAY平台吗？',
    selectOneData: '至少选择一条有效数据',
    /** 公用 */
    LoginFailed: '登陆失效',
    Relogin: '重新登陆',
    HaveBeenLoggedOut:
      '你已经被强制登出,你可以关闭弹窗停留在该页面或者重新登录',
    PasswordManagement: '密码管理',
    Password: '密码',
    Newpassword: '新密码',
    ConfirmPassword: '确认密码',
    ResetPassword: '重置密码',
    Character: '角色',
    Staff: '员工',
    InformationManagement: '信息管理',
    phoneNumber: '手机号',
    username: '用户名',
    Email: '邮箱',
    Avatar: '头像',
    requestTimeOut: '请求已超时,请重试.',
    homepage: '首页',
    Details: '详情',
    search: '搜索',
    reset: '重置',
    expand: '展开',
    hide: '隐藏',
    create: '新建',
    export: '导出',
    stockHistoryExport: '库存历史导出',
    startTimeEndTime: '开始时间—结束时间',
    No: '序号',
    gender: '性别',
    man: '男',
    woman: '女',
    inputPlaceholder: '请输入',
    inputTemplatetype: '请输入模板分类',
    selectPlaceholder: '请选择',
    selectTheErrors: '请选择问题件',
    enabled: '启用',
    disable: '禁用',
    operate: '操作',
    view: '查看',
    modify: '修改',
    invalid: '作废',
    createDate: '创建日期',
    createTime: '创建时间',
    receiveTime: '领取时间',
    finishTime: '完成时间',
    handleTime: '操作时间',
    createByName: '创建人',
    DesignatedWarehouse: '指定仓库',
    warehouse: '仓库',
    Linkman: '联系人',
    sure: '确定',
    delete: '删除',
    add: '新增',
    import: '导入',
    storageArea: '储区',
    close: '关闭',
    Specifications: '规格型号',
    total: '合计',
    purchaseNoteNO: '关联单号',
    associationNoteNO: '关联单号',
    status: '状态',
    Prompt: '提示',
    platformcreatetime: '平台创建时间',
    systemcreatetime: '系统创建时间'
  },
  title: {
    Group: '小组',
    'ImportSuccess': '导入成功',
    'UploadError': '上传失败请稍后再试!',
    'SizeLimitTip': '请上传{limit}以内的文件',
    'LimitTip': '上传文件个数最多{limit}个!',
    'FileTypeTip': '仅能上传{accept}类型文件',
    History: '历史',
    Update: '更新',
    Permission: '权限',
    back: '返回',
    Save: '保存',
    SaveAndSend: '保存并发布',
    Edit: '编辑',
    Replace: '替换',
    BasicInformation: '基本信息',
    submit: '提交',
    cancel: '取 消',
    confirm: '确 认',
    ExportDetail: '导出明细',
    // 平台SKU信息
    Color: '颜色',
    Size: '尺码',
    Category: '类别',
    Yes: '是',
    No: '否',
    Tobeshipped: '待发运',
    Shipped: '已发运',
    ToCShipping: '发运',
    Unshipped: '未发运',
    onelabledetail: '请至少选择一条标签明细',
    Abolished: '已作废',
    // OMS
    CommodityCategory: '商品一级类别',
    style: '样式',
    shipmentnumber: '物流单号',
    shippingInfo: '发运信息',
    Orderplatform: '订单平台',
    Platformsite: '平台站点',
    PlatformAccount: '平台账号',
    Amazon: '亚马逊',
    Distributableinventory: '可分配库存',
    Uploadinventory: '上传库存',
    selectainventory: '请至少选择一条上传库存',
    select: '请至少选中一条',

    ebayAmazon: 'ebay+亚马逊的数量不能超过可用库存',
    OrderType: '订单类型',
    OrderAll: '全部',
    primeorder: 'prime订单',
    Nonprimeorders: '非prime订单',
    ShippingStatus: '发运状态',
    ordernumber: '订单号',
    Refresh: '刷新',
    filter: '筛选',
    Batchvoid: '批量作废',
    BatchTransit: '批量转待调',
    Batchallocation: '批量分配订单',
    Batchreview: '批量审核',
    Batchexport: '批量导出',
    ordersmanually: '手动同步订单',
    Manualsync: '手动同步',
    Buyername: '买家姓名',
    BuyerEmail: '买家邮箱',
    orderamount: '订单金额',
    commodityTax: '商品税额',
    Shippingaddress: '收货地址',
    Partialshipment: '部分发货',
    Shippe: '已发货',
    Paymenttime: '付款时间',
    Generationtime: '生成时间',
    Deliverytime: '发货时间',
    Shippingwarehouse: '出货仓库',
    image: '图片',
    Specification: '规格型号',
    aQuantity: '要货量',
    Amount: '金额',
    quantityorder: '订单要货量',
    Freight: '运费',
    Currency: '币种',
    amountactuallypaid: '实付金额',
    Paymentdate: '付款日期',
    Buyer: '买家',
    phone: '电话',
    mailbox: '邮箱',
    destination: '目的地',
    Generationdate: '生成日期',
    Distributionwarehouse: '分配仓库',
    cancelorder: '确定作废订单么？',
    handsyncorders: '确定手动同步订单吗？',
    canceltip: '取消后无法恢复。确定要取消订单？',
    allocateorders: '确定批量分配吗',
    batchprocessing: '确定此操作吗',
    allocateSure: '分仓',
    BatchDistributionWarehouse: '批量分配仓库',
    Tobeallocated: '待分配',
    Staypointswarehouse: '待分仓',
    Assigned: '已分配',
    Havepointswarehouse: '已分仓',
    Pointsinthewarehouse: '分仓中',
    to: '至',
    orderinfo: '订单信息',
    Productinfo: '商品信息',
    CustomerInfo: '客户信息',
    OrderStatus: '订单状态',
    Noorder: '所选订单存在缺少手机号码，是否选择过滤后进行提交',
    noorder: '所选订单中存在不符合批量分配的明细',
    Assigning: '分配中',
    Deliverytype: '发货类型',
    Selfdelivery: '自发货',
    FBAdelivery: 'FBA发货',
    mixing: '混合',
    Orderproductinfo: '订单商品信息',
    Shippingmethod: '发货方式',
    shipmentsnum: '发货量',
    Parcelnumber: '包裹号',
    Shippingdate: '发运日期',
    Carrier: '承运商',
    Shippingtype: '发运类型',
    received: 'Received',
    Invalid: 'Invalid',
    Planning: 'Planning',
    Processing: 'Processing',
    Cancelled: 'Cancelled',
    Complete: 'Complete',
    CompletePartialled: 'CompletePartialled',
    Unfulfillable: 'Unfulfillable',
    Unabledeliver: '无法配送',
    OutOfDate: '已作废',
    starttime: '开始时间',
    endtime: '结束时间',
    TransfertoFBA: '转FBA发货',
    Cancelling: '取消中',
    Cancelleds: '已取消',
    singleenter: '输入单号多条请用英文,（逗号）分隔开',
    EnterItemID: '输入ItemID多条请用英文,（逗号）分隔开',
    CancelFBA: '取消FBA发运',
    number: '账号',
    platNumber: '平台订单号',
    associatedNumber: '关联订单号',
    replenishmentNumber: '补单号',
    platInfo: '平台信息',
    addressInfo: '地址信息',
    orderAmount: '订单金额',
    date: '日期',
    PlatformSku: '平台SKU',
    time: '时间',
    Finalrespondent: '最后回复人',
    Finalhandle: '最后处理人',
    Markasreplied: '标记已回复',
    Markasreaded: '标记为已读',
    Markasunread: '标记为未读',
    Markashandled: '标记已处理',
    Batchreply: '批量回复',
    Unreply: '未回复',
    draft: '草稿',
    replied: '已回复',
    errorSend: '发送失败',
    customerID: '客户ID',
    customerName: '客户全称',
    latestNews: '最新消息',
    Platformtopic: '平台账号/话题',
    latestnewsTime: '最新消息时间',
    platform: '平台',
    nextMessage: '下一封',
    order: '订单',
    copy: '复制',
    Attachedfile: '是否有附件',
    Receivedtime: '收件时间',
    Markasstar: '标记星标',
    emailsmanually: '手工拉取邮件',
    addresser: '发件人',
    None: '无',
    readed: '已读',
    unread: '未读',
    today: '今天',
    yesterday: '昨天',
    Inthreedays: '3天内',
    Insevendays: '7天内',
    Inamonth: '30天内',
    Systemdeliveryinformation: '系统发货信息',
    Platformmessage: '平台留言',
    Recipientaddress: '收件人地址',
    Listinginfo: 'listing信息',
    note: '备注',
    sure: '确定',
    title: '标题',
    content: '内容',
    operator: '操作人',
    classify: '分类',
    Presales: '售前',
    Aftersalesservice: '售后',
    Aftersalesserviceorder: '售后订单',
    Insalesservice: '售中',
    Customersevice: '新客户接待',
    Untreated: '未处理',
    treated: '已处理',
    send: '发送',
    Autonextmessage: '自动下一封',
    EnterSend: 'ctrl+enter键发送',
    topic: '主题',
    Addtemplate: '添加模板',
    classifyManage: '分类管理',
    Templateclassify: '模板分类',
    Templatetitle: '模板标题',
    Templatecontent: '模板内容',
    AddOrEditClassify: '添加/编辑分类',
    Add: '添加',
    HandleTimeOut: '仓库处理超时',
    ProductMismatch: '产品未匹配',
    ShippingConfigurationError: '发货配置错误',
    InsufficientInventory: '库存不足',
    Abolishing: '作废中',
    PartOfDistribution: '部分分配',
    PartOfDistributed: '部分已分配',
    LastTrackingFailed: '上传跟踪号失败',
    NotApproved: '审核不通过',
    AbnormalDistribution: '配送异常',
    ToBeReleased: '待发布',
    ToAudit: '待审核',
    Gettracefailed: '获取跟踪号失败',
    Release: '发布',
    Handle: '处理',
    Goback: '回退',
    Pushagain: '重推',
    abnormal: '异常',
    distributionsum: '分配量',
    Requiedinventory: '所需库存',
    Canuseinventory: '可用库存',
    Operationlog: '操作日志',
    OperationMethod: '操作动作',
    site: '站点',
    PlatForm: '平台',
    shopName: '店铺',
    amount: '总金额',
    Errorpackage: '问题件',
    TheerrorPackages: '所有问题件',
    create: '创建',
    waitingship: '待发货',
    lessship: '缺货',
    normal: '正常',
    DistributableInventory: '分配库存',
    // UNABLE_TO_GET_OUT_WAREHOUSE
    cannotgetoutwarehouse: '无法出库',
    handleMatched: '处理匹配',
    changeSku: '更换SKU',
    createRMA: '创建RMA',
    Notice: '确定修改提交当前数据吗?',
    everySKu: '请创建每一个SKU',
    savefirst: '请先保存修改',
    createfail: '创建失败',
    operatesuccess: '操作成功',
    copysuccess: '复制成功',
    cannotallocation: '所选订单中存在不符合条件的明细,是否选择过滤后进行提交',
    platformpaymenttime: '平台付款时间',
    Expecteddateofshipment: '预计发货时间',
    LatestDeliveryTime: '最迟发货时间',
    platformAutoCancelTime: '平台自动取消时间',
    Inventorytostaytuned: '库存待调',
    toTuned: '转待调',
    wmsOrderNumber: '出库单号',
    // 筛选
    orderPriceStart: '订单起始金额',
    orderPriceEnd: '订单结束金额',
    country: '国家',
    city: '城市',
    stateRegion: '区域',
    systemCreateDateStart: '系统创建开始时间',
    systemCreateDateEnd: '系统创建结束时间',
    paymentsDateStart: '平台付款开始时间',
    paymentsDateEnd: '平台付款结束时间',
    orderCreateDateStart: '平台创建开始时间',
    orderCreateDateEnd: '平台创建结束时间',
    sendDateStart: '发货开始时间',
    sendDateEnd: '发货结束时间',
    ProblemMentioned: '标签',
    TicketStatus: '工单状态',
    platforms: '平台站点',
    SellingAccount: '平台账号',
    Ticketno: '工单号',
    OrderCreationMethod: '建单方式',
    TicketDate: '工单日期',
    Productrank: '定位',
    keyword: '关键字',
    Unanswered: '未回复',
    Answered: '已回复',
    Draft: '草稿',
    Failedtosent: '发送失败',
    Remindfollow: '提醒跟进',
    Subject: '消息主题',
    Productinformation: '产品信息',
    Agent: '处理人',
    Respondingtime: '工单处理时间',
    FirstEmailTime: '客户首次来信时间',
    LatestLetterTime: '客户最新来信时间',
    LastReplyTime: '客服最后处理时间',
    ClosedTime: '工单完结时间',
    TimeType: '时间类型',
    Closepage: '关闭页面',
    Allmessages: ' 查看全部消息',
    Show: '显示',
    Product: '商品',
    Insert: '插入',
    Customerid: '客户昵称',
    CustomerID: '客户ID',
    Account: '账号名称',
    Agentname: '客服名称',
    Template: '回复模板',
    Producttitle: '标题',
    Preview: '内容预览',
    Quantity: '数量',
    FulfillmentMethod: '发货类型',
    Trackingnumber: '物流单号',
    Shippingtime: '发货时间',
    RMA: '关联售后单',
    RelatedOrder: '关联补单',
    Note: '备注信息',
    Transferto: '流转工单',
    Remind: '提醒',
    Blockedmail: '屏蔽邮箱',
    Name: '名称',
    emailaddress: '备注信息',
    Blockedtime: '屏蔽时间',
    Closedtickets: '已完结工单',
    Sentmessages: '已回复消息',
    Markedasread: '标记已回复',
    Averagerespondingtime: '首次回复平均响应时间',
    Longestrespondingtime: '最长回复时间',
    Shortestrespondingtime: '最短回复时间',
    Averageresponsetime: '平均处理时长',
    Transferringtime: '流转次数',
    Transferredtime: '被流转次数',
    PerfectWorkOrder: '完美工单',
    PerfectWorkOrderRate: '完美工单比例',
    BadReviewWorkOrder: '中差评工单数',
    Shippingwarehouses: '发货仓',
    Reviewed: '评论时间',
    Problemmentioned: '工单标签',
    Sentmessagestime: '回复消息数',
    Respondingtimes: '响应时间',
    SetUpRMA: '创建RMA单',
    All: '全部',
    Awaitingapproval: '待审核',
    Unshippeds: '待买家发货',
    Unreceived: '待收货',
    Received: '已收货',
    Deliveredreceived: '收货超时',
    Refunded: '已退款',
    Refused: '已拒绝',
    Failedrefund: '退款失败',
    Canceled: '已取消',
    Partiallyreceived: '部分收货',
    Refunding: '退款中',
    Transferring: '已退款待同步',
    Moreinformation: '对象',
    Refundinformation: '退款信息',
    Buyerpessage: '买家留言',
    Buyerpicture: '买家图片',
    stytemNote: '系统备注',
    Attachpicture: '卖家图片',
    Submit: '发布',
    Orderamount: '产品费',
    Additionalrefund: '额外退费/收费',
    Amounttorefund: '退费',
    Totalrefund: '总计',
    Customizedamount: '自定义金额',
    Buyermessage: '买家留言',
    Returnexchange: '退货换货',
    Trackingforreturn: '跟踪号',
    RMAid: '退货单号',
    Closed: '已关闭',
    Addfirstlevellabel: ' 添加一级标签',
    Addsecondarylabel: ' 添加二级标签',
    Addthreelevellabel: ' 添加三级标签',
    NewManageLabe: '新建/管理标签',
    finished: '已完结',
    Unfinished: '未完结',
    NewProduct: '新品',
    Customerids: '客户ID/客户名',
    Notshipped: '未发货',
    Subjectmail: '邮件主题',
    MessageID: '消息ID',
    Closeworkorder: '完结工单',
    administrator: '管理员',
    distribute: '分配',
    Remove: '移除',
    assignBU: '分配BU',
    EmailAccount: '邮箱地址',
    underreview: '审核中',
    Usetemplates: '使用模板',
    Deprecatedtemplates: '弃用模板',
    Addtemplates: '添加模板',
    Modifythetemplate: '修改模板',
    Submitreview: '提交审核',
    addcategory: '添加分类',
    username: '姓名',
    selectionperiod: '选择时间',
    types: '类型',
    day: '天',
    alreadyexists: '当前工单已有',
    continueeply: '在处理,是否继续回复!',
    Continuetoreply: '继续回复',
    Theoperation: '操作成功,自动跳转下一封',
    operationfailed: '操作失败',
    Syncdate: '同步日期',
    Pullmailmanually: '手工拉取邮件',
    Producttitles: '产品标题',
    Afterentering: '输入单号后，按下Enter，查询平台等信息',
    Bypercentage: '按百分比',
    Orderexception: '订单异常',
    ShippingWarehouse: '出货仓库',
    SendingTime: '发送时间',
    SentTimeMustBeLaterThanCreationTime: '发送时间须晚于创建时间',
    SentStatus: '发送状态',
    EmailAddress: '邮箱地址',
    QueryResultExport: '查询结果导出',
    ManualImport: '手工建单导入',
    deliveryAgeing: '发货时效',
    MCFCreationFailed: 'MCF创建失败',
    FBAHasNotShippedDueDate: 'FBA临期未发货',
    OutOfStockSubCategories: '库存不足子分类'
  },
  topic: {
    RMAType: 'RMA类型',
    RefundPoints: '是否退积分',
    RMAWay: 'RMA方式',
    Refunds: '退货退款',
    OnlyRefund: '仅退款',
    Manual: '手工',
    System: '系统',
    ReturnOrderNumber: '退货单号',
    PlatformOrderNumber: '平台订单号',
    ReturnWarehouse: '退货仓',
    Reviewtime: '审核时间',
    Paymenttime: '退款时间',
    Reviewer: '审核人',
    Moneymaker: '退款人',
    productname: '产品名称',
    Numberofrefunds: '退款数量',
    reasonforreturn: '退款原因',
    Refundamount: '退款金额',
    Receiptstatus: '收货状态',
    Refundmethod: '退款方式',
    generate: '生成',
    Submissiontime: '签收',
    refunds: '退款',
    review: '审核',
    Confirmreceipt: '确定收货',
    Reasonforfailure: '失败原因',
    Refundtypeoperation: '退款类型操作',
    Fullrefund: '全部退款',
    Partialrefund: '部分退款',
    BuyerID: '买家ID',
    Taxes: '税费',
    Shippingfeerefund: '运费退费',
    Promotionaldiscount: '促销折扣',
    Additionalrefunds: '额外退费',
    Expeditedshippingtax: '加急运费税费',
    Productexpeditedshipping: '产品加急运费',
    Producttaxes: '产品税费',
    Productfee: '产品费',
    Orderrefund: '订单退款',
    Agreeandrefund: '同意退货退款 ',
    fullrefund: '全额退款',
    Refuse: '拒绝',
    Typerefund: '退款类型',
    Refundtime: '退款时间',
    Refunder: '退款人',
    RMAstatus: 'RMA状态',
    Refundonly: '仅退款(未发货)',
    Refundshipped: '仅退款(已发货)',
    logisticsmethod: '请选择物流方式',
    Agreetoreturn: '同意退货',
    Additionaltax: '额外运费税',
    Extrashipping: '额外运费',
    Expeditedshipping: '加急运费',
    Expeditedfreighttax: '加急运费税',
    Ticktoshipping: '勾选代表退运费',
    Confirmdelivery: '确定发货',
    logisticsprovider: '请输入物流商',
    trackingnumber: '请输入物流跟踪号',
    Batchcancel: '批量取消',
    Partial: '待买家同意部分退款',
    refuses: '买家拒绝部分退款',
    BulkRefund: '批量退款'
  },
  order: {
    Address: '地址一',
    address: '地址二',
    Systemordernumber: '系统订单号',
    Sourcenumber: '来源单号',
    Platformordernumber: '平台单号',
    Systemordernumbers: '系统/来源单号',
    ApprovalStatus: '审核状态',
    fbaPlatform: '渠道',
    Fulfillmentmethod: '发货方式',
    Carrier: '物流方式',
    fbaSite: '账号站点',
    Sellingaccount: '店铺',
    Paymentstatus: '支付状态',
    Shippingnotes: '发货备注',
    Orderstatus: '订单状态',
    Systemreviewed: '已审核',
    Shippingstatus: '平台订单状态',
    Receipt: '收件人',
    Setupby: '创建人',
    Awaitingreview: '待审核',
    Rejected: '拒绝未通过',
    Unpaid: '未支付',
    Paid: '已支付',
    Pending: '未付款',
    Pendingshipment: '待发货',
    completed: '完成',
    Shipped: '已发货',
    Arrived: '已到达',
    Cancel: '取消',
    Platformfulfilled: '平台配送',
    Sellerfulfilled: '自己配送',
    UTCtime: 'UTC时间',
    Orderinformation: '订单信息',
    Receiptinformation: '收货信息',
    SystemID: '系统流水号',
    Currency: '币种',
    Orderamount: '订单总金额',
    Timezone: '时区',
    Purchasetime: '下单时间',
    Updatetime: '更新时间',
    Buyername: '买家姓名',
    Buyeremail: '买家邮箱',
    reAddress: '收货地址',
    Zipcode: '邮编',
    Country: '国家',
    ReturnRefund: '退货/退款',
    Productinformation: '商品信息',
    Totalamount: '商品总价',
    Trackingnumber: '跟踪单号',
    Chargedatails: '费用明细',
    Type: '费用类型',
    SubtotalRMB: '金额（本币）',
    ExchangerateUSD: '汇率（本币转美金）',
    Subtotal: '金额（美金）',
    Exchangerate: '汇率（美金转人民币）',
    Note: '发货备注',
    Orderinformations: '订单明细',
    Buyerinformation: '规格型号',
    States: '地区',
    Productinformations: '订单明细',
    Quantityenter: '数量(双击输入数量)',
    AmountEnter: '金额(双击输入金额，单位：￥)',
    KOLorder: ' 营销订单',
    Statesd: '州 / 区',
    RMB: '人民币',
    Log: '日志',
    types: '类型',
    Original: '原始内容',
    Changed: '调整内容:',
    OperationTime: '操作时间',
    LocalTime: '市场时间',
    Bejingtime: '北京时间',
    Detail: '详情',
    Replacement: '补单',
    AddSUK: '添加SKU',
    Pleaseshortname: '请输入简称',
    system: '系统',
    source: '来源',
    other: '其他',
    KOLAddress: '选择KOL收货地址'
  },
  tips: {
    GreaterThanMin: '推送库存量不能大于库存量最小值',
    InputNumber: '请输入数字',
    GreaterThan0: '您输入得数量不能小于0',
    ValueLesser: '您输入得数量不能大于原始数量',
    LesserThan20: '您输入的值不能大于20',
    EnterOriginal: '你输入得金额不能大于原始金额',
    Product: '商品信息不能为空',
    ThanOriginal: '您输入的金额不能大于原始金额或为空',
    GreaterThan: '您输入的百分比列不能小于0或为空',
    Lesser: '您输入的百分比列不能大于100%',
    Reinstated: '取消后无法恢复。确定要取消RMA单?',
    ConfirmReceive: '确定已收货？',
    ReceivingAlert: '收货提示',
    Cancellation: '取消提示',
    FalilureAlert: '失败原因提示',
    Falilure: '退款失败原因：',
    FalilureXML: '失败原始XML：',
    Sent: '发送成功',
    SnallerThan: '请上传小于 3M 的图片!',
    Copied: '复制成功',
    LesserThan: '大于30了',
    notEmpty: '备注信息不能为空,请重新输入!',
    GreaterThan30: '您输入的值不能大于30天',
    Selectleast: '请至少选择一条数据',
    Successful: '操作成功',
    Faileds: '操作失败',
    EmailRetrieved: '拉取邮件成功',
    Failed: '拉取邮件失败',
    Changed: '修改成功',
    failed: '修改失败',
    Deleted: '删除成功',
    faileds: '删除失败',
    enterContent: '请输入内容',
    Failedsend: '发送失败',
    ToBeSend: '待发送',
    Salespath: '销售路径',
    Oftenaging: '常规时效',
    Readytoday: '今日待发',
    Deliverytimeout: '发货超时',
    CorrectedSKU: '修正SKU',
    Pleasechooseplatform: '请选平台',
    entercountry: '请输入国家',
    entercity: '请输入城市',
    enterdistrict: '请输入州或区',
    Modifycustomerinfo: '修改客户信息',
    Incomplete: '客户信息不全',
    ReturnWarehouse: '退货仓',
    Sensitivewords: '敏感词',
    Addsensitivewords: '新增敏感词',
    Batchuse: '批量使用',
    Noreply: '暂不回复',
    Confirmbatchuse: '确认批量使用吗?',
    ResponseTime: '回复时间',
    Fraudulent: '欺诈订单',
    operation: '运营人员',
    refundAmountTips: '请再次确认退款金额无误!',
    labelCN: '请输入中文标签',
    labelEN: '请输入对应的英文标签',
    Onlysupport: '仅支持Purchase Order单号',
    PurchaseOrder: '平台订单号 / Purchase Order',
    enterPurchaseOrder: '请输入 Purchase Order',
    TransactionID: '交易ID修改',
    SKUModifiy: 'sku修改'
  },
  export: {
    download: '下载',
    exportDescription: '导出说明',
    successTip: '导出成功，请前往'
  },
  // 手工建单
  SimpleManual: {
    QuantityCannotBeEmpty: '数量不能为空',
    ProductImage: '产品主图',
    PriceEmpty: '商品金额为0，请修改金额'
  }
}
