export const StatusZH = {
  orderNumbersStr: '平台订单号',
  wmsOrderNumbersStr: '出库单号',
  itemIdsStr: 'ItemID',
  trackingNumber: '物流单号',
  buyerName: '买家姓名',
  buyerEmail: '买家邮箱',
  style: '样式',
  orderPriceStart: '订单起始金额',
  orderPriceEnd: '订单结束金额',
  countryCode: '国家',
  city: '城市',
  stateorregion: '区域',
  createDateStart: '系统创建开始时间',
  createDateEnd: '系统创建结束时间',
  paymentsDateStart: '平台付款开始时间',
  paymentsDateEnd: '平台付款结束时间',
  orderCreateDateStart: '平台创建开始时间',
  orderCreateDateEnd: '平台创建结束时间',
  sendDateStart: '发货开始时间',
  sendDateEnd: '发货结束时间',
  warehouseCodesStr: '出货仓库'
}

export const StatusEN = {
  orderNumbersStr: 'Platform bill number',
  wmsOrderNumbersStr: 'Outbound ordernumber',
  itemIdsStr: 'ItemID',
  trackingNumber: 'Shipment number ',
  buyerName: 'Buyer name',
  buyerEmail: 'Buyer Email',
  style: 'Style',
  orderPriceStart: 'order Price Start',
  orderPriceEnd: 'order Price End',
  countryCode: 'country',
  city: 'city',
  stateorregion: 'state Region',
  createDateStart: 'system Create Date Start',
  createDateEnd: 'system Create Date End',
  paymentsDateStart: 'payments Date Start',
  paymentsDateEnd: 'payments Date End',
  orderCreateDateStart: 'order Create Date Start',
  orderCreateDateEnd: 'order Create Date End',
  sendDateStart: 'send Date Start',
  sendDateEnd: 'send Date End',
  warehouseCodesStr: 'Shipping warehouse'
}
