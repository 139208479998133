<template>
  <div class="tool" @click.stop="jumpToFeishu">
    <svg-icon class="svg-icon" icon-class="customer-service" />
    <div class="title">在线客服</div>
  </div>
</template>

<script>
import settings from '@/settings'
export default {
  data() {
    return {
    }
  },
  methods: {
    jumpToFeishu() {
      window.open(settings.feishuCustomerService, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.tool{
  position: fixed;
  right: 5px;
  bottom: 15vh;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #46a6ff;
  transition: all 0.3s;
  &:hover{
    transform: scale(1.08);
    color: #1890ff;
  }
  .svg-icon{
    font-size: 35px;
  }
  .title{
    font-size: 12px;
  }
}
</style>
