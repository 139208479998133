import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const BedReviewToken = 'BedReview-Token'

export const JWT_TOKEN = 'jwt_token'
export const WG_OAUTH_REFRESH = 'wg_oauth_refresh'
export const WG_OAUTH = 'wg_oauth'

function getDomain() {
  const domain = document.domain || window.location.host
  if (domain.includes('wangoon.cn')) {
    return 'wangoon.cn'
  } else {
    return domain
  }
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: getDomain() })
}

export function removeToken() {
  Cookies.remove(TokenKey)
  Cookies.remove(JWT_TOKEN, { domain: getDomain() })
  Cookies.remove(WG_OAUTH_REFRESH, { domain: getDomain() })
  return Cookies.remove(TokenKey, { domain: getDomain() })
}
export function getTokenByKey(key) {
  return Cookies.get(key)
}
export function setTokenByKey(key, token) {
  return Cookies.set(key, token, { domain: getDomain() })
}

// oms  中差评 python
export function setBedReviewToken(token) {
  return Cookies.set(BedReviewToken, token)
}
export function getBedReviewToken() {
  return Cookies.get(BedReviewToken)
}

export function removeBedReviewToken() {
  Cookies.remove(BedReviewToken, { domain: 'wangoon.cn' })
  return Cookies.remove(BedReviewToken)
}
