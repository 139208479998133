import request from '@/utils/request'

export function getInfo(params) {
  return request({
    url: 'auth/users/current',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: 'auth/oauth/remove/token',
    method: 'delete'
  })
}

export function login(data) {
  return request({
    url: 'auth/oauth/user/token',
    method: 'post',
    data
  })
}

export function getMenus() {
  return request({
    url: 'auth/menus/current',
    method: 'get'
  })
}

// 新的菜单
export function getUserMenus(params) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}oms/common/pms/user/menus`,
    method: 'get',
    params
  })
}

// 新的菜单资源权限
export function getUserMenuRes(menuId) {
  return request({
    url: `${process.env.VUE_APP_OMS_API}oms/common/pms/user/menu/res/${menuId}`,
    method: 'get'
  })
}

/**
 *
 *
 * @description 角色管理模块
 * @memberOf summer1874
 * @date 2019-10-30
 */

// 查询所有角色
export function rolesAll() {
  return request({
    url: 'auth/roles/list',
    method: 'get'
  })
}

// 角色新增
export function rolesAdd(data) {
  return request({
    url: 'auth/roles',
    method: 'post',
    data
  })
}

// 角色修改
export function rolesEdit(data) {
  return request({
    url: 'auth/roles',
    method: 'put',
    data
  })
}

// 分页查询角色
export function rolesList(params) {
  return request({
    url: 'auth/roles/page',
    method: 'get',
    params
  })
}

// 根据ID查询角色
export function rolesQuery(id) {
  return request({
    url: `auth/roles/${id}`,
    method: 'get'
  })
}

// 角色逻辑删除
export function rolesIdDelete(id) {
  return request({
    url: `auth/roles/${id}`,
    method: 'delete'
  })
}
// 角色分配菜单
export function rolesGranted(params) {
  return request({
    url: `auth/roles/granted`,
    method: 'put',
    params
  })
}
/**
 *
 *
 * @description 菜单管理模块
 * @memberOf summer1874
 * @date 2019-10-30
 */

// 查询所有菜单树,包含按钮
export function menusAll() {
  return request({
    url: 'auth/menus/tree',
    method: 'get'
  })
}
// 删除菜单
export function menusDelete(id) {
  return request({
    url: `auth/menus/${id}`,
    method: 'delete'
  })
}

// 根据ID查询菜单
export function menusQuery(id) {
  return request({
    url: `auth/menus/${id}`,
    method: 'get'
  })
}

// 菜单新增
export function menusAdd(data) {
  return request({
    url: 'auth/menus',
    method: 'post',
    data
  })
}

// 菜单修改
export function menusEdit(data) {
  return request({
    url: 'auth/menus',
    method: 'put',
    data
  })
}
// 查询当前角色菜单树
export function menusRolesQuery(id) {
  return request({
    url: `auth/menus/tree/${id}`,
    method: 'get'
  })
}
/**
 *
 *
 * @description 部门管理模块
 * @memberOf summer1874
 * @date 2019-10-30
 */
// 查询部门树
export function deptTree() {
  return request({
    url: 'auth/dept/tree',
    method: 'get'
  })
}
// 根据ID查询部门
export function deptQuery(id) {
  return request({
    url: `auth/dept/${id}`,
    method: 'get'
  })
}

// 修改部门
export function deptPut(data) {
  return request({
    url: `auth/dept`,
    method: 'put',
    data
  })
}
// 添加部门
export function deptAdd(data) {
  return request({
    url: `auth/dept`,
    method: 'post',
    data
  })
}
// 删除部门
export function deptDelete(id) {
  return request({
    url: `auth/dept/${id}`,
    method: 'delete'
  })
}

/**
 *
 *
 * @description 用户管理模块
 * @memberOf summer1874
 * @date 2019-11-04
 */
// 分页查询用户
export function usersList(params) {
  return request({
    url: 'auth/users/page',
    method: 'get',
    params
  })
}
// 分页查询用户
export function taskUsersList(params) {
  return request({
    url: 'auth/users/list',
    method: 'get',
    params
  })
}
// 根据用户名查询用户
export function usersVerifyName(username) {
  return request({
    url: `auth/users/name/${username}`,
    method: 'get'
  })
}
// 用户新增
export function usersAdd(data) {
  return request({
    url: 'auth/users',
    method: 'post',
    data
  })
}

// 根据id查询用户
export function usersQueryById(id) {
  return request({
    url: `auth/users/${id}`,
    method: 'get'
  })
}

// 用户密码重置
export function usersPwReset(id) {
  return request({
    url: `auth/users/${id}/reset`,
    method: 'put'
  })
}
// 删除用户
export function usersDelete(id) {
  return request({
    url: `auth/users/${id}`,
    method: 'delete'
  })
}

// 更新用户信息
export function usersUpdata(data) {
  return request({
    url: `/auth/users`,
    method: 'put',
    data
  })
}

// 个人信息修改
export function usersEdit(data) {
  return request({
    url: '/auth/users/edit',
    method: 'put',
    data
  })
}
// 查询当前用户的租户信息
export function userTenantsInfo() {
  return request({
    url: `/wms-base/basewarehouse/userTenantsInfo`,
    method: 'get'
  })
}
//  获取所有字典数据
export function getAllDict() {
  return request({
    url: `/wms-base/dict/all`,
    method: 'get'
  })
}
//  获取商品信息字典数据
export function getAllCommodityDict() {
  return request({
    url: `/base/dict/all`,
    method: 'get'
  })
}
